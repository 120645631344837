
/* Layout */
.inner {
    @include inner;
    padding: 20px 16px;
}

/* Buttons */
.btn {
    @include layout-align(inline-flex);
    height: 48px;
    padding: 0 10px;
    border-radius: 5px;
    &.btn-default {
        background-color: $red;
        color: $white;
        font-weight: 500;
        &:disabled {
            background-color: $gray;
        }
        &.second {
            background-color: $gray;
        }

        &.team-rank-btn {
            position: absolute;
            top:11px;
            left:auto;
            right:16px;
            z-index:1;
            width: 60px;
            height: 28px;
            color: $green;
            font-size:$font-size-12;
            background-color: $light-gray;
            border-radius: 30px;
       }
    }
    &.btn-secondary {
        background-color: $white;
        color: $deep-red;
        font-weight: 500;
        &:disabled {
            background-color: $gray;
        }
    }
    &.btn-outline {
        border: 1px solid $red;
        color: $red;
    }
    &.btn-outline-02 {
        border: 1px solid $gray;
        color: $medium-gray;
        &.active{
            background-color: $red;
            color: $white;
            font-weight: 500;
            border-color: $red;
        }
    }
    &.btn-underline {
        position: relative;
        margin-right: 20px;
        padding: 10px 5px;
        border-bottom: 1px solid $light-gray;
        border-radius:0;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            z-index: 50;
            width: 100%;
            height: 2px;
            background-color: $red;
            transform: scaleX(0);
            transition: 0.3s;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            color: $red;
            font-weight: 700;
            &:before {
                transform: scaleX(1);
            }
        }
    }
    &.medium {
        width: 100%;
        max-width: 120px;
    }
    &.large {
        display: flex;
        margin: 0 auto;
        width: 100%;
    }
    &.block {
        width: 100%;
        height: 48px;
    }
    &.btn-gradient-black {
        background:linear-gradient(0deg, #333333, #333333), linear-gradient(271.19deg, #E10600 0%, #960E13 99.94%);
    }
    &.btn-gradient-red {
        background:linear-gradient(271.19deg, #E10600 0%, #960E13 99.94%);
    }
    &.btn-disabled-gray{
        background-color: $gray;
    }
}

/* Round Button */
.btn-round {
    min-width: 80px;
    height: 32px;
    border-radius: 16px;
    background-color: $gray;
    color: $white;
    font-size: 1.4rem;
    font-weight: 500;
    &.active {
        background-color: $red;
    }
    &.small {
        min-width: 49px;
        height: 24px;
        font-size: 1.0rem;
    }
    &.line {
        border:1px solid $gray;
        background: $white;
        color: $black;
    }
}

/* Button Wrap */
.button-wrap {
    &.center {
        text-align: center;
    }
    .btn {
        margin-right: 14px;
        &:last-child {
            margin-right: 0;
        }
    }
}

// 좋아요 버튼
.btn-action-like {
    position: relative;
    margin-left: -9px;

    label {
        display: flex;
        align-items: center;
        .count {
            font-size: $font-size-14;
            color: $medium-gray;
            word-break: break-all;
        }

        cursor: pointer;
    }

    svg {
        cursor: pointer;
        overflow: visible;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        transition: .2s ease;
    }

    svg #heart {
        transform-origin: center;
        fill: transparent;
        stroke: $medium-gray;
        stroke-width: 2px;
        transition: .2s ease;
    }

    svg .circle {
        transform-origin: 29.5px 29.5px;
    }

    input {
        display: none;
    }

    input:checked + label svg #heart {
        transform: scale(0);
        fill: $red;
        stroke: $red;
        animation: heart 0.25s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }

    input:checked + label svg .circle {
        animation: circle .25s forwards;
    }

    input:checked + label svg #grp1 {
        opacity: 1;
        transition: 0.1s 0.2s;
    }

    input:checked + label svg #grp1 #oval1 {
        transform: scale(0) translate(0, -30px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp1 #oval2 {
        transform: scale(0) translate(10px, -50px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp2 {
        opacity: 1;
        transition: 0.1s 0.2s;
    }

    input:checked + label svg #grp2 #oval1 {
        transform: scale(0) translate(30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp2 #oval2 {
        transform: scale(0) translate(60px, -15px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp3 {
        opacity: 1;
        transition: 0.1s 0.2s;
    }

    input:checked + label svg #grp3 #oval1 {
        transform: scale(0) translate(30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp3 #oval2 {
        transform: scale(0) translate(60px, 10px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp4 {
        opacity: 1;
        transition: 0.1s 0.2s;
    }

    input:checked + label svg #grp4 #oval1 {
        transform: scale(0) translate(30px, 15px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp4 #oval2 {
        transform: scale(0) translate(40px, 50px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp5 {
        opacity: 1;
        transition: 0.2s all 0.2s;
    }

    input:checked + label svg #grp5 #oval1 {
        transform: scale(0) translate(-10px, 20px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp5 #oval2 {
        transform: scale(0) translate(-60px, 30px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp6 {
        opacity: 1;
        transition: 0.2s 0.2s;
    }

    input:checked + label svg #grp6 #oval1 {
        transform: scale(0) translate(-30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp6 #oval2 {
        transform: scale(0) translate(-60px, -5px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp7 {
        opacity: 1;
        transition: 0.2s 0.2s;
    }

    input:checked + label svg #grp7 #oval1 {
        transform: scale(0) translate(-30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.2s transform 0.2s;
    }

    input:checked + label svg #grp7 #oval2 {
        transform: scale(0) translate(-55px, -30px);
        transform-origin: 0 0 0;
        transition: 1.2s transform 0.2s;
    }

    input:checked + label svg #grp2 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    input:checked + label svg #grp3 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    input:checked + label svg #grp4 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    input:checked + label svg #grp5 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    input:checked + label svg #grp6 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    input:checked + label svg #grp7 {
        opacity: 1;
        transition: 0.2s opacity 0.2s;
    }

    @keyframes circle {
        from {
            transform: scale(0) translateY(-0.05px);
            stroke-width: 3px;
        }

        50% {
            transform: scale(6) translateY(-0.05px);
            stroke-width: 3px;
        }

        to {
            transform: scale(15) translateY(-0.05px);
            stroke-width: 0;
        }
    }

    @keyframes heart {
        from {
            transform: scale(0.2);
        }

        to {
            transform: scale(1);
        }
    }
}

/* Tabs */
.tab-basic {
    @include layout-align;
    li {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.tab-scroll {
    @include layout-align($justify-content: flex-start);
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 30px;
    .btn {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    &::-webkit-scrollbar {
        width: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.tab-swiper {
    .swiper-slide {
        width: auto;
    }
}

/* Swiper */
.swiper {
    position: relative;

    // pagination
    .swiper-pagination {
        bottom: 0 !important;
        font-size: 0;
        .swiper-pagination-bullet {
            width: 4px;
            height: 4px;
            margin: 0 3px !important;
            opacity: 0.6;
            background-color: $medium-gray;
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    // navigation
    [class*="swiper-button-"] {
        position: absolute;
        top: calc(50% - 12px);
        z-index: 1;
        width: 24px;
        height: 24px;
        margin-top: 0;
        background: url(#{$common}/arr_swiper.svg) 50% 50% no-repeat;
        &.swiper-button-prev {
            left: 0;
        }
        &.swiper-button-next {
            right: 0;
            transform: rotate(180deg);
        }
        &:after {
            display: none;
        }
    }
}

/* Checkbox */
.checkbox-basic {
    position: relative;
    .text {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        font-weight: 500;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -1px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $gray url(#{$common}/icon_checkbox.svg) 50% 50% no-repeat;
            transition: 0.3s;
        }
    }
    input {
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        &:checked {
            + .text:before {
                background-color: $red;
            }
        }
    }
}

/* Text List */
.text-list-basic {
    li {
        @include line-height($font-size-11);
        position: relative;
        margin-bottom: 5px;
        padding-left: 10px;
        font-size: $font-size-11;
        color: $medium-gray;
        word-break: keep-all;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 4px;
            height: 1px;
            background-color: $gray;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.text-list-dot {
    li {
        @include line-height($font-size-11);
        position: relative;
        padding-left: 8px;
        font-size: $font-size-11;
        color: $medium-gray;
        word-break: keep-all;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 3px;
            height: 3px;
            background-color: $medium-gray;
            border-radius: 50%;
        }
    }
}

/* Loader */
.loader {
    @include layout-align;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.5);
    .sprite {
        width: 150px;
        height: 250px;
        background: url(#{$common}/loading_02.png) 0 0 repeat-x;
        //background: url(#{$common}/loading.gif) 0 0 no-repeat;
        background-size: auto 100%;
        animation: sprite 1500ms 0.3s steps(59) infinite;
    }
}

@keyframes sprite {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 100%;
    }
}

/* Loading Spinner */
.loading-spinner {
    height: 150px;
    background: url(#{$common}/loading_spinner.svg) 50% 50% no-repeat;
    background-size: 50px;
}

/* Modal */
.modal-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.53);
    .modal {
        @include layout-align;
        width: 100%;
        height: 100%;

        &.nextSeezn-modal{
            .modal-body{
                background-color:transparent;
            }
        }
        .modal-body {
            position: relative;
            min-width: 310px;
            background-color: #fff;
            .modal-content {
                overflow: auto;
                position: relative;
                z-index: 50;
                max-height: 50vh;
            }
            .btn-modal-close {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 50;
                width: 44px;
                height: 44px;
                background: url(#{$common}/btn_modal_close.svg) 50% 50% no-repeat;
                background-size: 14px;
                font-size: 0;

                &.white-close {
                    top:-40px;
                    width: 40px;
                    height: 40px;
                    background: url(#{$common}/btn_modal_close_w.svg) center center no-repeat;
                }
            }
        }
        &.attendance-modal {
            .modal-body {
                background:transparent;
                .modal-content {
                    overflow:visible;
                }
                &.point,
                &.gift {
                    margin-top:-100px;
                }
                &.cookie {
                    margin-top:-140px;
                }
            }
        }
        .button-wrap {
            padding: 28px 0;
        }
    }
}

/*  Infinite Scroll */
.infinite-scroll-component {
    overflow: visible !important;
}

/* 에러 페이지 */
.error-page {
    @include layout-align;
    min-height: 100vh;
    .message {
        width: 100%;
        font-size: $font-size-24;
        font-weight: 500;
        color: $dark-gray;
        text-align: center;
        line-height: 1.3;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100px;
            margin-bottom: 30px;
            background: url(#{$common}/error.svg) calc(50% + 10px) 50% no-repeat;
            background-size: auto 100%;
        }
    }
}

/* seezn out modal component */
.next-seezn {
    width:100%;
    height:330px;
    background: url(#{$common}/seezn_out.png) center center no-repeat;
    background-size:cover;
}

/* 휴면 계정 안내 */
.dormant_account_wrap {
    padding:35px 16px 30px;
    position:relative;
    .greeting {
        color:$black;
        font-size:16px;
        font-weight:400;
        line-height:1.4;
        strong {
            font-weight:700;
        }
    }
    .chg_dates {
        margin-top:22px;
        dl {
            border-radius:5px;
            box-shadow:0 0 2px rgba(0, 0, 0, .2);
            font-size:0;
            height:48px;
            margin-top:10px;
            padding:0 20px;
            width:100%;
            &:first-of-type {
                margin-top:0;
            }
            dt, dd {
                display:inline-block;
                font-size:14px;
                height:100%;
                line-height:48px;
                width:50%;
            }
            dt {
                color:$dark-gray;
                text-align:left;
            }
            dd {
                color:$black;
                text-align:right;
            }
        }
    }
    .warn {
        margin-top:22px;
        li {
            color:$medium-gray;
            font-size:12px;
            line-height:1.4;
            margin-top:10px;
            padding-left:18px;
            position:relative;
            &:first-child {
                margin-top:0;
            }
            &:before {
                background:$medium-gray;
                border-radius:50%;
                content:"";
                height:4px;
                left:7px;
                position:absolute;
                top:7px;
                width:4px;
            }
        }
    }
    button {
        background:$red;
        border:0;
        border-radius:5px;
        color:$white;
        font-size:16px;
        font-weight:500;
        height:48px;
        margin-top:40px;
        text-align:center;
        width:100%;
    }
}

.out-link-wrap {
    height:100vh;
    position:relative;
    width:100%;
    & >.cont {
        background:url(#{$common}/char_out_link.svg) center top / 132px auto no-repeat;
        left:0;
        padding-top:208px;
        position:relative;
        text-align:center;
        top:50%;
        -webkit-transform:translateY(-60%);
        transform:translateY(-60%);
        width:100%;
        & > strong {
            color:$black;
            display:block;
            font-size:16px;
            font-weight:500;
        }
        & > p {
            color:$medium-gray;
            font-size:14px;
            margin-top:14px;
        }
    }
}
