.page-modal {
    header {
        height: 50px;
        border-bottom: 1px solid $light-gray;

        .title {
            @include layout-align;
            height: 100%;
            padding: 0 50px;
            font-size: $font-size-17;
            font-weight: 700;
        }

        .btn-modal-close {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            background: url(#{$common}/btn_modal_close_dark.svg) 50% 50% no-repeat;
            font-size: 0;
        }
    }

    .modal-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100vh - 50px);
        padding: 30px 16px;

        .content {
            overflow-y: auto;
            flex-grow: 1;
        }

        .button-wrap {
            margin-top: 16px;
        }
    }
}

/* 마케팅 수신 동의 */
.marketing-content {
    .title {
        @include line-height($font-size-17);
        margin-bottom: 20px;
        font-size: $font-size-17;
        font-weight: 700;
        word-break: keep-all;
    }

    p {
        @include line-height($font-size-12);
        margin-bottom: 20px;
        font-size: $font-size-12;
        word-break: keep-all;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .marketing-md-text {
        &.color-666 {
            color: #666;
        }

        font-size:13px;
    }

    .marketing-list {
        @include layout-align($justify-content: space-between);
        margin-bottom: 10px;
        border: 1px solid $light-gray;
        border-radius: 5px;
        box-shadow: 0px 0px 3px #DCDCDC;

        &:last-of-type {
            margin-bottom: 20px;
        }

        dt,
        dd {
            @include line-height($font-size-14);
            padding: 14px 20px;
            font-size: $font-size-14;
        }

        dt {
            flex-shrink: 0;
            color: $dark-gray;
        }

        dd {
            padding-left: 0;
            font-weight: 500;

            em {
                color: $red;
            }
        }
    }
}

.policy2-content {
    h2 {
        font-size: 16px;
        font-weight: 700;
    }

    p {
        font-size: 13px;
    }

    .policy-lg-text {
        font-size: 14px;
        line-height: 1.5;
    }

    .policy-md-text {
        &.color-666 {
            color: #666;
        }

        font-size:13px;
        line-height: 2;
    }

    .policy-sm-text {
        font-size: 12px;
        line-height: 1.5;
    }

    .pdt-10 {
        padding-top: 10px;
    }

    .pdt-15 {
        padding-top: 15px;
    }

    .pdb-20 {
        padding-bottom: 20px;
    }

    .pdt-30 {
        padding-top: 30px;
    }

    .pdt-40 {
        padding-top: 40px;
    }

    .pdb-40 {
        padding-bottom: 40px;
    }

    ul {
        li {
            h3 {
                padding-bottom: 15px;
            }

            h4 {
                padding-bottom: 15px;
            }

            h3,
            h4,
            p {
                font-size: 13px;
                line-height: 1.4;
            }
        }
    }

    .ol-title {
        padding-bottom: 20px;
    }


    .ol-content {
        table {
            table-layout: fixed;
            width: 100%;

            th:nth-child(1) {
                width: 20%;
            }

            th:nth-child(2) {
                width: 40%;
            }

            th:nth-child(3) {
                width: 40%;
            }

            th {
                background-color: #f8cbad;
                width: 100%;
            }

            th,
            td {
                padding: 5px 0;
                font-size: 13px;
            }

            td {
                padding: 5px 3px;
            }
        }

        table,
        th,
        td {
            border: 1px solid black;
            text-align: center;
            vertical-align: top;
        }

        h4 {
            font-size: 14px;
        }
    }



    ul {
        li {
            padding-top: 40px;
        }

        ol {
            li {
                padding-top: 15px;
            }
        }
    }

    dl {
        margin-bottom: 10px;

        dt {
            padding-bottom: 10px;
            font-size: 13px;

            h4 {
                padding: 0;
            }
        }

        dd {
            font-size: 13px;
            padding-bottom: 10px;
        }
    }

    .dot-text {
        display: flex;

        &:before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            margin: 7px 5px 0 0;
            border-radius: 100%;
            background-color: #111;
        }

        span {
            width: calc(100% - 10px);
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;

            i {
                font-style: unset;
                font-size: 14px;
                color: #E10600;
            }
        }
    }
}