/* Table */
.workspace-table-wrap {
    padding: 25px;
    .workspace-table {
        width: 100%;
        border-top: 2px solid #000;
    }
}

.workspace-table colgroup {
    .depth {
        width: 15%;
        min-width: 80px;
    }
    .type {
        width: 8%;
    }
    .page-link{min-width: 150px;}
    .date,
    .worker,
    .status {
        width: 15%;
        min-width: 70px;
    }
}

.workspace-table th,
.workspace-table td {
    padding: 12px 10px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
    line-height: 1.3;
    &:not(.api-change):last-child {
        border-right-width: 0;
    }
    &th {
        font-weight: 700;
        background-color: #f2f2f2;
    }
    &td {
        color: #555;
        transition: 0.3s;
    }
}

.workspace-table tbody tr:hover td {
    background-color: rgba(0, 0, 0, 0.02);
}

/* Type */
.workspace-type.popup {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    border-radius: 15px;
}

/* Link */
.workspace-table td a {
    display: block;
    font-weight: 700;
    text-decoration: underline;
    word-break: break-word;
}

/* Status */
.workspace-table .workspace-status {
    font-weight: 700;
    &.complete {
        color: #4eb693;
    }
    &.modify {
        color: #4e5eb6;
    }
    //&:before {
    //    content: '';
    //    display: inline-block;
    //    width: 25px;
    //    height: 25px;
    //    margin-right: 5px;
    //    background-size: contain;
    //    background-position: 0 0;
    //    background-repeat: no-repeat;
    //    vertical-align: middle;
    //}
}
