.my-ticket-wrap {
    .top-description{
        width:calc(100% + 32px);
        margin:-20px 0 0 -16px;
        padding:10px 0;
        background:$white-gray;
        font-size:1.2rem;
        color:$medium-gray;
        text-align: center;
    }
}

.ticket-list {
    border-bottom:1px solid $light-gray;

    .title-wrap {
        padding: 20px 0;
        position: relative;
        .date {
            display: block;
            margin-bottom: 10px;
            font-size: $font-size-12;
            font-weight: 400;
            color: $medium-gray;
        }
        .title {
            display: block;
            font-weight: 400;
        }
        &:after{
            content:'';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 14px;
            height: 100%;
            background: url(#{$common}/select_arrow.svg) 100% 50% no-repeat;
        }
    }
    &.active {
        padding-bottom: 20px;
    }
    &.active .title-wrap {
        &:after{
            transform: rotate(-180deg);
        }
    }

    &.active .coupon {
        display: flex;
        align-items:center;
    }

    .coupon {
        display: none;
        justify-content:space-between;
        padding: 18px 15px;
        border-radius:5px;
        background: $white-gray;
        .num {
            font-weight: 400;
        }

    }
}

// POINTER
.point-wrap{
    background-color: $black;
    .point-head{
        padding:30px 16px 0;

        .point-info {
            display: flex;
            align-items: center;
            justify-content: center;
            height:40px;
            background-color:$white;
            border-radius: 30px;

            .nickName {
                font-weight: 900;
            }
            .point{
                font-weight: 900;
            }
            .deco-point{
                padding-top:3px;
            }
        }
    }

    .point-bn{
        text-align: center;
    }

    .point-container{
        padding:0 16px;
    }

    .point-guide{
        padding:30px 15px;
        background-color: $white;
        border-radius: 5px;

        .point-guide-top{
            text-align: center;
            .mtxt {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size-16;;
                font-weight: 700;
                z-index: 1;
                height: 36px;
                span{

                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    i{
                        position: relative;
                        z-index: 1;
                        font-style: unset;
                        font-weight:700;
                    };
                    &:before {
                        content: "";
                        position: absolute;
                        top:-6px;
                        left:-6px;
                        display: block;
                        width: 64px;
                        height: 36px;
                        background-image: url("../images/myTicket/icon_deco_code.png");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size:contain;
                        z-index: 0;
                    }
                }
            }
            .stxt{
                padding-top:10px;
                font-size: $font-size-12;;
                line-height:1.5;
                b {
                    font-size: $font-size-12;;
                    font-weight: 700;
                }
            }
        }

        .point-guide-ex{
            padding-top:10px;
            p {
                padding-left:19px;
                font-size:$font-size-12;
                color:$medium-gray;
            }
            .point-guide-code{
                display:flex;
                align-items: center;
                justify-content:space-between;
                padding:18px 15px;
                margin:4px 19px 0;
                background-color:$red;
                border-radius: 5px;
                p {
                    color:$white;
                    &.codeNum{
                        font-size:$font-size-14;
                    }
                    &.copy {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius:30px;
                        width:49px;
                        height: 24px;
                        border:1px solid $gray;
                        padding-left:0;
                        font-size: $font-size-10;
                        color:$black;
                        background-color:$white;
                    }
                }
            }
        }

        .point-guide-txt {
            padding-top: 10px;
            text-align:center;
            line-height: 1.3;
            p {
                font-size: $font-size-12;
                color:$medium-gray;
            }
        }
    }

    .point-coupon-box {
        position: relative;
        margin-top:30px;
        text-align: center;
        .coupon-bg{
            width: 100%;
            padding-top:45.7%;
            background-image: url("../images/myTicket/bg_coupon.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .point-bottom{
        padding: 30px 0;
        button {
            display: block;
            width: 100%;
            height: 48px;
            border-radius: 5px;
            font-size:$font-size-16;
            font-weight:700;
            color:$white;
            background-color: #FFCB42;
            &.disabled{
                background-color: $gray;
            }
        }
        a {
            display: flex;
            align-items:center;
            justify-content:center;
            width: 100%;
            height: 48px;
            margin-top: 10px;
            border-radius: 5px;
            font-size:$font-size-16;
            font-weight:700;
            color:$black;
            background-color: $white;
        }
    }
}


.buy-code-wrap{
    position:fixed;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content:center;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0.5);
    z-index:1000;

    .buy-code-inr {
        width: 90%;

        .buy-code-head{
            display:flex;
            align-items: center;
            justify-content: right;

            .buy-code-close{
                width:30px;
                height:30px;
                background-image:url("../images/myTicket/modal_close_btn.svg");
                background-repeat: no-repeat;
            }
        }

        .buy-code-content{
            margin-top:40px;
            padding:60px 25px 30px;
            background-color:$white;
            border-radius:5px;

            .buy-code-msg{
                position: relative;
                .deco-img{
                    position: absolute;
                    top:-100px;
                    left:0;
                    display: block;
                    width: 100%;
                    height: 80px;

                    img{
                        max-width: unset;
                        max-height: 100%;
                    }
                }
                text-align: center;
                .mtxt {
                    font-size:$font-size-16;
                    font-weight: 700;
                }
                .stxt{
                    padding-top:10px;
                    font-size:$font-size-12;
                    line-height:1.4;
                }
            }

            .code-box{
                display:flex;
                align-items: center;
                justify-content: space-between;
                margin-top:20px;
                padding:18px 15px;
                background-color: $red;
                border-radius: 5px;

                .codeNum {
                    width:calc(100% - 45px);
                    height: auto;
                    padding:0;
                    border: 0;
                    font-size:$font-size-14;
                    color:$white;
                }

                .copy-btn {
                    width:49px;
                    height: 24px;
                    font-size: $font-size-10;
                    color:$black;
                    border-radius:30px;
                    background-color:$white;
                    border:1px solid $gray;
                }
            }

            .code-link{
                margin-top:10px;
                .btn{
                    display: block;
                    width: 100%;
                    height: 40px;
                    font-size:$font-size-16;
                    color:$red;
                    border:1px solid $red;
                    background-color: #fff;
                    text-align: center;
                    border-radius: 5px;
                    line-height: 39px;
                }
            }
        }

    }
}

.tost-msg-wrap {
    position:fixed;
    top:auto;
    left:0;
    bottom:40px;
    z-index:1001;
    width:100%;
    height:48px;

    .tost-msg {
        width: 90%;
        height:100%;
        margin:0 auto;
        font-size:$font-size-14;
        color:$white;
        text-align: center;
        line-height:48px;
        border-radius:5px;
        background-color:$black;
    }
}

.no-purchase-result {
    left:50%;
    position:absolute;
    text-align:center;
    top:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
    & > * {
        display:block;
        line-height:1.4;
    }
    strong {
        color:$black;
        font-size:$font-size-16;
        font-weight:500;
    }
    span {
        color:$medium-gray;
        font-size:$font-size-14;
        font-weight:400;
        margin-top:10px;
    }
}
