// 페퍼 라이브 - ON, YOUTUBE 등
.live-posts {
    &.video-list {
        .inner {
            padding-top: 10px;
        }
    }

    .tab-swiper {
        margin-bottom: 30px;
    }

    .video-select-wrap {
        position: sticky;
        top: calc(#{$headerHeight});
        z-index: 500;
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 20px 16px;
        background-color: $white;
        transition: 0.3s;

        .video-select {
            @include select;
            width: 100%;
            background-color: $white;
        }
    }

    .live-post-list {
        position: sticky;
        top: calc(20px + 46px + #{$headerHeight});

        .live-post {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                @include ellipsis;
            }
        }
    }

    .live-post {
        .iframe-wrap {
            position: relative;
            margin-bottom: 10px;

            &.archive-iframe-wrap {
                &:after {
                    padding-bottom: 177.659574%;
                }

                .youtube_box {
                    padding: 90%;
                }
            }

            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            video {
                width: 100%;
                height: 100%;
            }

            .youtube_box {
                width: 100%;
                padding: 30%;
            }
        }

        .thumb {
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            margin-bottom: 10px;

            img {
                width: 100%;
            }

            &.square {
                height: 0;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
        }

        .title {
            @include line-height;
            font-weight: 500;
        }

        .total {
            @include layout-align;
            flex-direction: column;
            position: absolute;
            right: 0;
            top: 0;
            width: 39%;
            height: 100%;
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.8);
            color: $white;
            word-break: break-all;
            text-align: center;

            &:after {
                content: '';
                margin-top: 10px;
                width: 24px;
                height: 24px;
                background: url(#{$video}/icon_total.svg) 50% 50% no-repeat;
            }
        }

        .live-info {
            @include layout-align($justify-content: flex-start);
            width: 100%;
            margin-top: 10px;

            span {
                position: relative;
                margin-right: 8px;
                padding-right: 8px;
                font-size: $font-size-12;
                color: $medium-gray;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: calc(50% - 1px);
                    width: 2px;
                    height: 2px;
                    background-color: $medium-gray;
                    border-radius: 50%;
                }

                &:last-child {
                    margin-right: 0;
                    padding-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .thumb {
            &:before {
                content: '';
                position: absolute;
                left: 8px;
                top: 8px;
                z-index: 10;
                width: 40px;
                height: 28px;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 100%;
            }
        }

        &.origin {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_pepper_on.png);
                }
            }
        }

        &.youtube {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_youtube.svg);
                }
            }
        }

        &.photo {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_pepper_on.png);
                }
            }
        }

        &.instagram {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_instagram.svg);
                }
            }
        }

        &.naver-post {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_naver_post.svg);
                }
            }
        }

        &.naver-tv {
            .thumb {
                &:before {
                    background-image: url(#{$common}/icon_naver_tv.svg);
                }
            }
        }
    }

    /*&.youtube-post,
    &.archive-post {
        .thumb {
            &:before {
                background-image: url(#{$common}/icon_youtube.svg);
            }
        }
    }
    &.on-post {
        .thumb {
            &:before {
                background-image: url(#{$common}/icon_pepper_on.svg);
            }
        }
    }*/
    &.archive-post {
        .live-post-list {
            display: flex;
            flex-wrap: wrap;

            .live-post {
                width: calc(50% - 8px);

                &:nth-child(odd) {
                    margin-right: 16px;
                }

                /*.live-info {
                    flex-direction: column;
                    align-items: flex-start;
                }*/
            }
        }
    }
}

// 시리즈 상세
.video-view {
    .inner {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - #{$headerHeight});
        padding-bottom: 0;

        .live-post {
            width: calc(100% + 32px);
            margin-left: -16px;
            margin-bottom: 10px;
            padding: 0 16px 5px;
            border-bottom: 1px solid $light-gray;

            .video-action-bar {
                @include layout-align($justify-content: flex-start);
                flex-wrap: wrap;
                margin-top: 20px;

                li {
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .view-comment {
                    @include layout-align($justify-content: flex-start);
                    height: 35px;
                    padding-left: 28px;
                    background: url(#{$video}/icon_comment.svg) 0 50% no-repeat;
                    font-size: $font-size-14;
                    color: $medium-gray;
                }
            }
        }

        .video-comment {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .comment-body {
                display: flex;
                flex-grow: 1;

                .none-result-comment {
                    @include layout-align;
                    flex-direction: column;
                    width: 100%;
                    padding: 30px 0;

                    .title {
                        margin-bottom: 10px;
                        color: $black;
                        font-weight: 500;
                    }

                    .description {
                        font-size: $font-size-14;
                        font-weight: 400;
                        color: $medium-gray;
                    }
                }
            }
        }
    }
}

// 댓글
.video-comment {
    .comment-head {
        @include layout-align($justify-content: space-between);
        margin-bottom: 10px;

        .comment-title {
            flex-shrink: 0;
            padding-right: 10px;
            font-size: $font-size-14;
            color: $medium-gray;
            font-weight: 500;

            .comment-total {
                color: $red;
            }
        }

        .comment-sort {
            @include layout-align;

            label {
                position: relative;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            .text {
                display: block;
                position: relative;
                padding-left: 20px;
                font-size: $font-size-14;
                color: $medium-gray;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    border-radius: 50%;
                    transform: translateY(-50%);
                    transition: 0.3s;
                }

                &:before {
                    width: 14px;
                    height: 14px;
                    border: 1px solid $gray;
                }

                &:after {
                    left: 4px;
                    width: 8px;
                    height: 8px;
                    background-color: $white;
                }
            }

            input:checked+.text {
                &:before {
                    border-color: $red;
                }

                &:after {
                    background-color: $red;
                }
            }
        }
    }

    .comment-body {}

    .comment-foot {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 10px 16px 30px;
        background-color: $white;
        border-top: 1px solid $light-gray;

        .enter-form {
            padding: 12px 0;
            background-color: $white-gray;
            border-radius: 3px;
            transition: 0.3s;

            textarea {
                @include scrollbar;
                @include line-height($font-size-14);
                height: 20px;
                padding: 0 48px 0 10px;
                background-color: transparent;
                border-width: 0;
                font-size: $font-size-14;
                outline: 0;
            }

            .btn-enter-comment {
                content: '';
                display: block;
                position: absolute;
                right: 16px;
                top: calc(50% - 34px);
                z-index: -1;
                width: 48px;
                height: 48px;
                background: url(#{$video}/icon_enter.svg) 50% 50% no-repeat;
                font-size: 0;
                opacity: 0;
                transition: 0.3s;

                &.active {
                    z-index: 50;
                    opacity: 1;
                }
            }
        }

    }
}

.comment-list {
    width: 100%;

    .comment-item {
        @include layout-align($justify-content: space-between, $align-items: flex-start);
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 16px;
        font-size: $font-size-14;

        .avatar {
            flex-shrink: 0;
            margin-right: 10px;

            img {
                width: 40px;
                height: 40px;
                max-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                border: 1px solid $gray;
                object-fit: cover;
            }
        }

        .comment-content {
            flex-grow: 1;

            .comment-user {
                @include layout-align($justify-content: flex-start);
                @include line-height($font-size-12);
                margin-bottom: 5px;
                font-size: $font-size-12;
                color: $medium-gray;

                .nickname {
                    @include layout-align;
                    position: relative;
                    margin-right: 8px;
                    padding-right: 8px;

                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 1px);
                        width: 2px;
                        height: 2px;
                        background-color: $medium-gray;
                        border-radius: 50%;
                    }

                    &.is-player {
                        &:before {
                            @include layout-align($display: inline-flex);
                            flex-shrink: 0;
                            content: '';
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                            background: $red url(#{$video}/icon_player.svg) 50% 50% no-repeat;
                            border-radius: 50%;
                        }
                    }
                }

                .date {
                    flex-shrink: 0;
                }
            }

            .comment-text {
                @include line-height($font-size-14);
                font-size: $font-size-14;

                .author {
                    margin-right: 5px;
                    color: $green;
                }
            }

            .comment-icons {
                @include layout-align($justify-content: flex-start);
                flex-wrap: wrap;

                li {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .btn-action-like {
                        margin-left: -7px;

                        svg {
                            width: 28px;
                            height: 28px;
                        }

                        .count {
                            font-size: $font-size-12;
                        }
                    }

                    .btn-comment-write {
                        display: block;
                        height: 28px;
                        padding-left: 22px;
                        background: url(#{$video}/icon_comment.svg) 0 50% no-repeat;
                        background-size: 15px;
                        font-size: $font-size-12;
                        color: $medium-gray;
                    }
                }
            }
        }

        .comment-more {
            flex-shrink: 0;
            width: 24px;

            .btn-comment-more {
                width: 24px;
                height: 24px;
                background: url(#{$video}/btn_comment_more.svg) 50% 50% no-repeat;
                font-size: 0;
            }
        }
    }

    // 대댓글
    &.sub-comment-list {
        .comment-item {
            padding-left: 66px;

            .avatar {
                img {
                    width: 30px;
                    height: 30px;
                    max-width: 30px;
                    max-height: 30px;
                    min-height: 30px;
                }
            }
        }

        >li:first-child {
            .author {
                display: none;
            }
        }
    }

    // 댓글
    &:not(.sub-comment-list) {
        >li {
            border-bottom: 1px solid $white-gray;

            &:last-child {
                border-bottom-width: 0;
            }
        }
    }

    // 내 댓글
    .is-my {
        >.comment-item {
            background-color: $white-gray;
        }
    }
}

.bottom-sheet {
    @include layout-align($align-items: flex-end);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: 0.3s;

    .sheet-content {
        position: relative;
        z-index: 100;
        width: 100%;
        padding: 18px 0;
        background-color: $white;
        border-radius: 20px 20px 0 0;
        transform: translateY(100%);
        transition: transform 0.4s cubic-bezier(0.38, 0.09, 0.12, 1);

        button {
            display: block;
            width: 100%;
            padding: 12px 16px;
            text-align: left;

            em {
                color: $red;
            }
        }
    }

    .dim {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.active {
        z-index: 5000;
        opacity: 1;

        .sheet-content {
            transform: translateY(0);
        }
    }
}