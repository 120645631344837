@charset "utf-8";

// Color
$black: #000;
$medium-black: #333;
$white: #fff;

$white-gray: #f4f4f4;
$light-gray: #ededed;
$gray: #dcdcdc;
$medium-gray: #999;
$dark-gray: #666;

$red: #e10600;
$dark-red: #960e13;
$deep-red: #72160d;

$burgundy: #600000;

$green: #006845;

// Break Point
$media-mini-phone: 359px;
$media-phone: 767px;
$media-tablet: 1279px;

// Font Family
$noto-sans: 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, sans-serif;
$black-han: 'Black Han Sans', 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, sans-serif;
$aggro: SBAggro, 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, sans-serif;
$ink: InkLipquid, 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, sans-serif;

// Font Size
$font-size-10: 1.0rem;
$font-size-11: 1.1rem;
$font-size-12: 1.2rem;
$font-size-13: 1.3rem;
$font-size-14: 1.4rem;
$font-size-16: 1.6rem; // default
$font-size-17: 1.7rem;
$font-size-18: 1.8rem;
$font-size-20: 2rem;
$font-size-24: 2.4rem;

// Route
$common: "../images/common";
$main: "../images/main";
$player: "../images/player";
$attendance: "../images/attendance";
$broadcast: "../images/broadcast";
$live: "../images/pepperLive";
$event: "../images/event";
$about: "../images/about";
$ticket: "../images/ticket";
$team: "../images/team";
$video: "../images/video";
$membership: "../images/membership";

// Etc
$headerHeight: 50px;
