.live-list-wrap {
    &.vertical {
        ul{
            display: flex;
            flex-wrap: wrap
        }
        .live-list{
            width: 49%;
            &:nth-child(2n) {
                margin-left: 2%;
            }
        }
    }
}
.live-list {
    width: 100%;
    margin-bottom: 20px;

    .img-wrap {
        overflow:hidden;
        position: relative;
        margin-bottom: 10px;
        border-radius: 5px;
        &.monopoly{
            &:before{
                content: '';
                position: absolute;
                left: 8px;
                top: 8px;
                z-index: 10;
                width: 44px;
                height: 32px;
                background: url(#{$live}/logo_monopoly_lg.png) 50% 50% no-repeat;
                background-size: contain;
            }
        }
        &.complete {
            &:before {
                content: '';
                position: absolute;
                left: 8px;
                top: 8px;
                z-index: 10;
                width: 44px;
                height: 32px;
                background: url(#{$live}/logo_youtube.png) 50% 50% no-repeat;
                background-size: 100%;
            }
        }

        img {
            width: 100%;
        }
    }

    .text-wrap {
        .title{
            @include ellipsis;
            margin-bottom: 5px;
            font-weight: 400;
            line-height: 1.5;
        }
        .description{
            display: block;
            font-size: $font-size-12;
            color: $medium-gray;
            .date{
                position: relative;
                padding-left: 12px;
                margin-left: 10px;
                &:before{
                    content:'';
                    position: absolute;
                    left: 0;
                    top:8px;
                    width: 2px;
                    height: 2px;
                    border-radius:2px;
                    background: $medium-gray;
                }
            }
        }
    }

}
