.merchandise {
    .inner {
        @include layout-align($justify-content: initial, $align-items: initial);
        flex-direction: column;
        min-height: calc(100vh - #{$headerHeight});
    }
    .tab-swiper {
        margin-bottom: 30px;
    }
}

.none-merchandise {
    @include layout-align;
    @include line-height;
    flex-grow: 1;
    height: 100%;
    text-align: center;
    word-break: keep-all;
}

.merchandise-list {
    @include layout-align($justify-content: flex-start, $align-items: flex-start);
    flex-wrap: wrap;
    > li {
        width: calc( 50% - 5px );
        margin-top: 20px;
        &:nth-child(odd) {
            margin-right: 10px;
        }
        &:nth-child(-n+2) {
            margin-top: 0;
        }
    }
}

.merchandise-description {
    display: block;
    cursor: pointer;
    .image-wrap {
        overflow: hidden;
        position: relative;
        height: 0;
        padding-bottom: calc( 100% - 2px );
        margin-bottom: 10px;
        border: 1px solid $light-gray;
        border-radius: 5px;
        text-align: center;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .detail-list {
        li {
            margin-bottom: 3px;
            line-height: 1.3;
            &.age {
                color: $medium-gray;
                font-size: $font-size-11;
            }
            &.title {
                @include ellipsis;
                font-size: $font-size-14;
            }
            &.price {
                font-size: $font-size-12;
                color: $medium-gray;
                .discount {
                    margin-right: 5px;
                    color: $red;
                    font-weight: 700;
                }
                .num {
                    text-decoration: line-through;
                    word-break: break-all;
                }
            }
            &.final-price {
                font-weight: 700;
                word-break: break-all;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
