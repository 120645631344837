.attendance {
    .attendance-notice {
        margin-top: 54px;
        margin-bottom: 30px;
        text-align: center;
        .notice-box {
            position: relative;
            padding: 0 16px;
            &:not(:last-child) {
                margin-bottom: 34px;
            }
            .title {
                position: absolute;
                left: calc(50% - 91px);
                top: -14px;
                width: 182px;
                height: 34px;
                padding-top: 10px;
                background: url(#{$attendance}/bg_notice_title.svg) 50% 50% no-repeat;
                font-size: $font-size-14;
                color: $white;
                font-weight: 700;
            }
            .description {
                @include line-height;
                width: 100%;
                max-width: 600px;
                min-height: 80px;
                margin: 0 auto;
                padding: 35px 20px 20px;
                background: $white;
                border-radius: 10px;
                word-break: keep-all;
                em {
                    font-weight: 700;
                }
            }
        }
    }
    .attendance-list-wrap {
        position: relative;
        padding-top: 338px;
        background: url(#{$attendance}/bg.png) 50% 0 repeat;
        background-size: auto 100%;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 358px;
            background: url(#{$attendance}/head.png) 50% 0 no-repeat;
            background-size: auto 100%;
        }
    }
}

.attendance-list {
    position: relative;
    z-index: 50;
    background: url(#{$attendance}/line.svg) calc(50% - 32px) 21px no-repeat;
    //background-size: 360px;
    padding-bottom: 55px;
    li {
        @include layout-align($justify-content: flex-start);
        width: 280px;
        margin: 0 auto;
        margin-bottom: 29px;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(even) {
            flex-flow: row-reverse;
        }
    }
}

.attendance-item {
    position: relative;
    margin: 0 10px;
    width: 50px;
    height: 76px;
    text-align: center;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        background-color: $white;
        border-radius: 50%;
    }
    .btn-attendance {
        @include layout-align;
        position: relative;
        z-index: 10;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        border-radius: 50%;
        background-color: #efefef;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border: 1px dashed $medium-gray;
        font-family: $aggro;
        font-size: $font-size-10;
        font-weight: 500;
        color: $red;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        word-break: break-all;

        &.point {
            &.complete {
                background-image: url(#{$attendance}/ball.svg);
            }
        }
        &.cookie {
            background-image: url(#{$attendance}/cookie.svg);
            background-size: 48px;
            &.past:not(.complete) {
                background-image: url(#{$attendance}/cookie_disabled.svg);
            }
            &.complete {
                background-image: url(#{$attendance}/cookie_complete.svg);
                background-size: 100%;
            }
        }
        &.gift {
            background-image: url(#{$attendance}/gift.svg);
            background-size: 30px;
            font-size: 0;
            &.past:not(.submit-complete) {
                background-image: url(#{$attendance}/gift_disabled.svg);
                background-size: 30px;
            }
            &.complete {
                &.submit-complete {
                    background-image: url(#{$attendance}/gift_complete.svg);
                    background-size: 100%;
                }
                &.none-submit {
                    background-image: url(#{$attendance}/gift_none_submit.svg);
                    background-size: 100%;
                    border-color: $medium-black;
                }
            }
        }
        &.past:not(.complete) {
            border-color: $gray;
            color: $gray;
            text-shadow: none;
        }
        &.complete:not(.none-submit) {
            border-width: 0;
            transform: rotateY(360deg);
            transition: transform 0.5s;
        }
        &.today {
            border-color: $red;
        }
        &.future {
            opacity: 0.3;
        }
    }
    .date {
        font-size: $font-size-12;
    }
}

.attendance-guide {
    max-width: 425px;
    margin: 0 auto;
    padding: 30px 16px;
    .title {
        margin-bottom: 10px;
        font-size: $font-size-12;
        font-weight: 500;
    }
}

/* Modal */
.attendance-modal {
    .modal-body {
        max-width: 310px;
        &.point-ani .button-wrap,
        &.cookie-ani .button-wrap,
        &.gift-ani .button-wrap,
        &.point-ani .btn-modal-close,
        &.cookie-ani .btn-modal-close,
        &.gift-ani .btn-modal-close {
            display:none;
        }
        &.point .button-wrap,
        &.cookie .button-wrap,
        &.gift .button-wrap {
            display:block;
        }
        &.point .btn-modal-close,
        &.cookie .btn-modal-close,
        &.gift .btn-modal-close {
            display:none;
        }
    }
    .modal-content {
        [class*='-area'] {
            &.cookie-area {
                height: 250px;
                background: url(#{$attendance}/bg_cookie.svg) 50% 50% no-repeat;
                .content {
                    min-height: 130px;
                }
            }
            &.gift-area {
                height: 258px;
                background: url(#{$attendance}/bg_gift.svg) 50% 50% no-repeat;
                .content {
                    min-height: 120px;
                }
            }
            .content {
                @include layout-align;
                flex-direction: column;
                padding: 20px;
                .sub-title {
                    margin-bottom: 10px;
                    font-size: $font-size-12;
                    font-weight: 700;
                    color: $red;
                    text-align: center;
                }
                .title,
                .name {
                    word-break: keep-all;
                    text-align: center;
                    &.title {
                        @include line-height($font-size-17);
                        margin-bottom: 10px;
                        font-family: $ink;
                        font-size: $font-size-17;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    &.name {
                        font-size: $font-size-16;
                    }
                }
            }
        }
        & + .button-wrap {
            background:#fff;
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
            margin-top:-1px;
            padding-top: 0;
            position:relative;
            z-index:50;
        }
    }
}

.new-rwd-pop.cookie {
    height:353px;
    & > .top {
        background:url(#{$attendance}/cookie_pop_deco.svg) center top / 100% auto no-repeat;
    }
}
.new-rwd-pop.point {
    height:268px;
    & > .top {
        background:url(#{$attendance}/point_pop_deco.svg) center top / 100% auto no-repeat;
    }
}
.new-rwd-pop.gift {
    height:275px;
    & > .top {
        background:url(#{$attendance}/gift_pop_deco.svg) center top / 100% auto no-repeat;
        top:20px;
    }
}

.new-rwd-pop {
    & > .top {
        height:170px;
        left:0;
        position:absolute;
        top:0;
        width:100%;
        z-index:5;
    }
    & > .cont {
        background:#fff;
        border-top-left-radius:5px;
        border-top-right-radius:5px;
        bottom:0;
        height:auto;
        padding:56px 0 20px;
        position:absolute;
        width:100%;
        & > * {
            color:$black;
            display:block;
            text-align:center;
        }
        strong {
            font-size:16px;
            font-weight:700;
        }
        .cookie-txt-bg {
            align-items:center;
            background:url(#{$attendance}/cookie_txt_bg.svg) center top / 262px auto no-repeat;
            display:flex;
            font-size:12px;
            font-weight:400;
            height:74px;
            justify-content:center;
            line-height:1.4;
            margin-top:10px;
            padding:0 36px;
        }
        p {
            font-size:12px;
            font-weight:400;
            line-height:1.4;
            margin-top:10px;
            b {
                font-size:12px;
                font-weight:700;
            }
        }
    }
}

.attendance-button-wrap {
    max-width: 425px;
    margin: 0 auto;
    padding: 16px;
}

.attendance_anipop_dimm {
    background:$black;
    height:100%;
    left:0;
    opacity:.5;
    position:fixed;
    top:0;
    width:100%;
    z-index:102;
}

.ani_pop {
    height:100%;
    left:0;
    position:fixed;
    top:0;
    width:100%;
    z-index:105;
}

.point_ani {
    background: url(#{$attendance}/point_get_ani.gif) center 160px / 325px auto no-repeat rgba(0, 0, 0, 0.53);
}
.cookie_ani {
    background: url(#{$attendance}/cookie_get_ani.gif) center 160px / 325px auto no-repeat rgba(0, 0, 0, 0.53);
}
.gift_ani {
    background: url(#{$attendance}/gift_get_ani.gif) center 160px / 325px auto no-repeat rgba(0, 0, 0, 0.53);
}
