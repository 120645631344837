.ticket-wrap{
    .inner {
        padding-bottom: 14px;
    }

    .banner {
        a {
            display: block;
            padding-top: 16.8%;
            background: url(#{$ticket}/banner.png) 0 0 no-repeat;
            background-size: 100%;
        }
    }
    .ticket-img{
        font-size: 0;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 112%;
        background: url(#{$ticket}/seat.png) 0 0 no-repeat;
        background-size: 100%;
    }

    .information{
        margin-top: 10px;
        .title{
            margin-bottom: -10px;
        }

        .text-list li{
            position: relative;
            font-size: 1.2rem;
            color: $medium-gray;
            line-height: 1.6;
            padding-left: 10px;
            &:before {
                content:'-';
                position: absolute;
                left: 0;
                top: 0px;
            }

            &.sub-title{
                margin-top: 20px;
                padding-left: 15px;
                &:before {
                    content:'※'
                }
            }
            &.list-title {
                margin-top: 10px;
                padding-left: 0;
                &:before {
                    content:"";

                }
            }
        }
    }

    .ticket-inform {
        border-top:10px solid $white-gray;
        padding:30px 16px;
        .title {
            color:$black;
            font-size:12px;
            font-weight:500;
            margin-bottom:20px;
            &.mt20 {
                margin-top: 20px;
            }
        }
        .text-list li{
            position: relative;
            font-size: 1.2rem;
            color: $medium-gray;
            line-height: 1.6;
            padding-left: 10px;
            &:before {
                content:'-';
                position: absolute;
                left: 0;
                top: 0px;
            }
            &.sub-title{
                margin-top: 20px;
                padding-left: 15px;
                &:before {
                    content:'※'
                }
            }
            &.list-title {
                margin-top: 10px;
                padding-left: 0;
                &:before {
                    content:"";

                }
            }
            &.no-pad {
                padding-left: 0;
                &:before {
                    content:"";

                }
            }
            &.mt10 {
                margin-top: 10px;
            }
        }
    }

    .button-wrap{
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 500;
        width: 100%;
        padding: 16px;
        background-color: #fff;
    }
}

.ticket-price-wrap{
    display: flex;
    flex-wrap:wrap;

    .ticket-price{
        display: block;
        position: relative;
        width: 48%;
        margin-bottom: 20px;
        padding: 25px 10px 65px;
        border-radius:5px;
        text-align: center;
        box-shadow:0 0 3px $gray;

        .mark{
            position: absolute;
            top: -4px;
            left: 10px;
            display: block;
            width: 54px;
            height: 25px;
            padding-top: 4px;
            background-size: 100% !important;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
            color: $white;
        }

        .title{
            display: block;
            margin-bottom: 5px;
            font-size: 1.6rem;
            font-weight: 700;
        }
        
        .description{
            font-size: 1.2rem;
            color: $green;
        }

        .sub-desc {
            color: $medium-gray;
            display:block;
            font-size: 11px;
            line-height:1.4;
            margin-top:5px;
        }

        .price{
            display: flex;
            justify-content:center;
            align-items:center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            padding: 13px 0;
            border-radius:0 0 5px 5px;
            font-size: 1.4rem;
            font-weight: 700;
            color: $white;
            background: #000;
        }
        
        &.family{
            width: 100%;
            .mark{
                background: url(#{$ticket}/seat_mark_family.png) 0 0 no-repeat;
            }
            .price{
                background: #9F74BF;
            }
        }

        &.ai-a{
            .mark{
                background: url(#{$ticket}/seat_mark_ai_a.png) 0 0 no-repeat;
            }
            .price{
                background: #D8905F;
            }
        }

        &.ai-b{
            margin-left: 4%;
            .mark{
                background: url(#{$ticket}/seat_mark_ai_b.png) 0 0 no-repeat;
            }
            .price{
                background: #FFCB42;
            }
        }

        &.lotte-outlet {
            width: 100%;
            .mark{
                background: url(#{$ticket}/seat_mark_lotte.png) 0 0 no-repeat;
            }
            .price{
                background: #912079;
            }
        }

        &.crazy{
            margin-left: 4%;
            .mark{
                background: url(#{$ticket}/seat_mark_crazy.png) 0 0 no-repeat;
            }
            .price{
                background: #FF4C4C;
            }
        }

        &.pepper{
            .mark{
                background: url(#{$ticket}/seat_mark_pepper.png) 0 0 no-repeat;
            }
            .price{
                background: #7C9CD6;
            }
        }

        &.puma{
            .mark{
                background: url(#{$ticket}/seat_mark_puma.png) 0 0 no-repeat;
            }
            .price{
                background: $black;
            }
        }

        &.esg{
            .mark{
                background: url(#{$ticket}/seat_mark_esg.png) 0 0 no-repeat;
            }
            .price{
                background: #B2DE84;
            }
        }

        &.ediya {
            margin-left: 4%;
            .mark{
                background: url(#{$ticket}/seat_mark_ediya.png) 0 0 no-repeat;
            }
            .price{
                background: #23276F;
            }
        }

        &.away {
            .mark{
                background: url(#{$ticket}/seat_mark_away.png) 0 0 no-repeat;
            }
            .price{
                background: #008740;
            }
        }

        &.regular {
            margin-left: 4%;
            .mark{
                background: url(#{$ticket}/seat_mark_regular.png) 0 0 no-repeat;
            }
            .price{
                background: #ff69d8;
            }
        }

        &.third-floor {
            margin-left: 4%;
            .mark{
                background: url(#{$ticket}/seat_mark_third.png) 0 0 no-repeat;
            }
            .price{
                background: #e66834;
            }
        }

    }
}
