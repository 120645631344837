
.select-wrap {
    
    strong {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    select {
        border: 1px solid #DCDCDC;
        border-radius: 5px;
        height: 48px;
        padding: 0px 15px;
        width: 100%;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;


        background: url(#{$common}/select_arrow.svg) center right 17px / 18px auto no-repeat;
    }
}

.policy-wrap {
    $default-font-size: 13px;
    .cont{
        margin-top: 20px;
        font-family: $noto-sans;
        font-size: $default-font-size;
        line-height: 20px;
        word-break: break-word;
        
        br {
            content: "";
            display: block;
            margin-top: $default-font-size;
        }

        p {
            font-size: $default-font-size;
            line-height: 20px;

            span {
                strong {
                    font-size: inherit;
                }

                b {
                    font-size: inherit;
                }
            }
        }

        strong {
            font-size: $default-font-size;
            font-weight: 700;
        }

        b {
            font-size: $default-font-size;
            font-weight: 700;
        }


    }


}