.realtime_chat_wrap {
    height:calc(100vh - 136px);
}
.live_game {
    background:$black;
    height:0;
    padding-bottom:55.556%;
}
.realtime_chat_ttl {
    height:48px;
    position:relative;
    strong {
        color:$black;
        font-size:16px;
        font-weight:500;
        left:16px;
        line-height:48px;
        padding-right:37px;
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        i {
            background:url(#{$broadcast}/live_badge.svg) right center / 100% auto no-repeat;
            display:block;
            height:12px;
            margin-top:-6px;
            overflow:hidden;
            position:absolute;
            right:0;
            text-indent:-20000px;
            top:50%;
            width:32px;
        }
    }
    button {
        background:url(#{$broadcast}/icon_eye.svg) 12px center / 18px auto no-repeat;
        border:1px solid $light-gray;
        border-radius:14px;
        color:$black;
        font-size:12px;
        font-weight:500;
        height:28px;
        line-height:26px;
        margin-top:-14px;
        padding:0 12px 0 36px;
        position:absolute;
        right:16px;
        top:50%;
    }
}
.chat_list_wrap {
    height:55.556%;
    overflow-y:auto;
    padding:20px 16px 100px;
    li {
        margin-top:10px;
        padding:10px 16px;
        &:first-child {
            margin-top:0;
        }
        &.from {
            background:$white-gray;
            border-radius:10px 10px 10px 0;
            p {
                color:$black;
                white-space: pre-wrap;
            }
        }
        &.to {
            background:$dark-red;
            border-radius:10px 10px 0 10px;
            p {
                color:white;
                white-space: pre-wrap;
            }
        }
        .card_t {
            &:after {
                clear:both;
                content:"";
                display:block;
            }
            & > * {
                color:$medium-gray;
                float:left;
                font-size:12px;
                font-weight:500;
            }
            span {
                margin-left:10px;
                padding-left:10px;
                position:relative;
                &:before {
                    background:$medium-gray;
                    border-radius:50%;
                    content:"";
                    height:2px;
                    margin-top:-1px;
                    left:0;
                    position:absolute;
                    top:50%;
                    width:2px;
                }
            }
            a, button {
                float:right;
                text-decoration:underline;
            }
        }
        p {
            font-size:14px;
            font-weight:400;
            line-height:1.4;
            margin-top:10px;
        }
    }
}
.fixed_chat_input {
    background:$white;
    border-top:1px solid $light-gray;
    bottom:0;
    left:0;
    padding:10px 16px 30px;
    position:fixed;
    right:0;
    width:100%;
    z-index:5;
    .input_form {
        background:$white-gray;
        border-radius:3px;
        padding:12px 0;
        transition:0.3s;
        textarea {
            background:transparent;
            border:0;
            font-size:1.4rem;
            height:20px;
            line-height:1.4;
            outline:0;
            overflow-y:auto;
            padding:0 48px 0 10px;
        }
        button {
            background:url(#{$video}/icon_enter.svg) 50% 50% no-repeat;
            content:"";
            display:block;
            height:48px;
            opacity:1;
            overflow:hidden;
            position:absolute;
            right:16px;
            text-indent:-20000px;
            top:calc(50% - 34px);
            transition:0.3s;
            width:48px;
            z-index:1;
            &.active {
                opacity:1;
                z-index:50;
            }
        }
    }
}
.ban_chat_layer_dimm {
    background:#000;
    height:100%;
    left:0;
    opacity:.3;
    position:fixed;
    top:0;
    width:100%;
    z-index:6;
}
.ban_chat_layer {
    background:$white;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    bottom:0;
    box-shadow:0 0 5px rgba(0, 0, 0, .2);
    height:500px;
    left:0;
    padding:0 16px;
    position:fixed;
    right:0;
    width:100%;
    z-index:7;
    .ban_chat_layer_top {
        height:36px;
        position:relative;
        &:after {
            background:#79747e;
            border-radius:2px;
            content:"";
            height:4px;
            left:50%;
            margin:-2px 0 0 -16px;
            position:absolute;
            top:50%;
            width:32px;
        }
    }
    .now_watching {
        color:$black;
        font-weight:500;
        padding:10px 0;
        text-align:center;
    }
    .chat_search_box {
        border:1px solid $red;
        border-radius:5px;
        height:40px;
        margin-top:10px;
        position:relative;
        & > i {
            background:url(#{$broadcast}/btn_search_r.svg) center / 100% auto no-repeat;
            display:block;
            height:14px;
            left:19px;
            margin-top:-7px;
            position:absolute;
            top:50%;
            width:14px;
            z-index:5;
        }
        & > input {
            border:0;
            color:$medium-gray;
            font-size:14px;
            height:100%;
            padding:0 20px 0 43px;
            width:100%;
        }
    }
    .now_watching_list {
        height:378px;
        overflow-y:auto;
        padding-bottom:10px;
        & > li {
            margin-top:10px;
            min-height:40px;
            padding:0 110px 0 50px;
            position:relative;
            & > img {
                border:1px solid $gray;
                border-radius:50%;
                height:40px;
                left:0;
                margin-top:-20px;
                position:absolute;
                top:50%;
                width:40px;
            }
            & > p {
                color:$black;
                font-size:12px;
                line-height:40px;
                overflow:hidden;
                position:relative;
                text-overflow:ellipsis;
                white-space:nowrap;
                &.icon_p {
                    background:url(#{$broadcast}/icon_player.svg) left center / 16px auto no-repeat;
                    padding-left:22px;
                }
            }
            & > .btn_wrap {
                font-size:0;
                height:30px;
                margin-top:-15px;
                position:absolute;
                right:0;
                text-align:right;
                top:50%;
                button {
                    border-radius:15px;
                    color:$white;
                    display:inline-block;
                    font-size:12px;
                    font-weight:500;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    width:46px;
                    &.red {
                        background:$red;
                    }
                    &.gray {
                        background:$gray;
                        margin-left:8px;
                    }
                }
            }
        }
    }
}
.report_pop_wrap {
    background:$white;
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
    z-index:100;
    .header {
        align-items:center;
        background-color:#fff;
        border-bottom:1px solid #ededed;
        display:flex;
        height:50px;
        justify-content:center;
        padding:0 50px;
        position:sticky;
        top:0;
        z-index:100;
        button.back {
            background:url(#{$common}/btn_page_back.svg) center / 12px auto no-repeat;
            font-size:0;
            height:50px;
            left:0;
            overflow:hidden;
            position:absolute;
            text-indent:-20000px;
            top:0;
            width:50px;
        }
        h2 {
            font-weight:700;
        }
    }
    .cont {
        padding:29px 16px 26px;
        position:relative;
        & > figure {
            text-align:center;
        }
        & > dl {
            margin-top:35px;
            dt {
                color:$black;
                font-size:16px;
                font-weight:500;
            }
            dd {
                border-radius:5px;
                height:auto;
                margin-top:18px;
                .Dropdown-control {
                    border:1px solid $gray;
                    border-radius:5px;
                    color:$black;
                    height:48px;
                    padding:15px 52px 15px 15px;
                    .Dropdown-arrow {
                        background:url(#{$broadcast}/sel_arrow_d.svg) center / 100% auto no-repeat;
                        border:0;
                        height:8px;
                        position:absolute;
                        right:14px;
                        top:19px;
                        width:14px;
                    }
                }
                .Dropdown-root.is-open .Dropdown-arrow {
                    background:url(#{$broadcast}/sel_arrow_u.svg) center / 100% auto no-repeat;
                }
                .Dropdown-menu {
                    border:1px solid $gray;
                    border-radius:5px;
                    box-shadow:none;
                    max-height:inherit;
                    .Dropdown-option {
                        color:$black;
                        font-size:16px;
                        font-weight:400;
                        padding:17px 15px;
                    }
                }
                .txt_input {
                    height:204px;
                    overflow:hidden;
                    position:relative;
                    textarea {
                        border:1px solid $gray;
                        border-radius:5px;
                        font-size:16px;
                        font-weight:400;
                        height:100%;
                        padding:18px 14px;
                        resize:none;
                        width:100%;
                    }
                    p {
                        bottom:17px;
                        color:$medium-gray;
                        font-size:14px;
                        font-weight:400;
                        position:absolute;
                        right:15px;
                        span {
                            color:$medium-gray;
                            font-size:14px;
                            font-weight:400;
                        }
                    }
                    &.warn {
                        textarea {
                            border:1px solid $red;
                        }
                        p {
                            color:$red;
                        }
                    }
                }
            }
        }
        & > button.submit {
            background:$gray;
            border-radius:5px;
            color:$white;
            font-size:16px;
            font-weight:700;
            height:48px;
            line-height:48px;
            margin-top:30px;
            width:100%;
            &.on {
                background:$red;
            }
        }
    }
}
