.match-top-banner {
    position: relative;
    .match-top-bn{
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index: 0;
        width: 100%;
        padding-top:15.71%;
        background-image: url(../images/match/match_top_bn.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.match-head {
    width:100%;
    padding:15.71% 0 0;
    overflow:auto;


    ul {
        position: relative;
        @include layout-align($justify-content:flex-start);
        padding:20px 16px 0;
        margin-bottom:0;
        &:after{
            content: "";
            display: inline-block;
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            z-index: -1;
            width:100%;
            height: 1px;
            background-color: $light-gray;
        }
    }
    li {
        margin-left:20px;
        &:first-child {
            margin-left:0;
        }
        &.active {
            .tab-btn {
                color:#E10600;
                font-weight:700;
            }

            .tab-btn:before{
                background-color:#E10600;
            }
        }

        .tab-btn {
            position:relative;
            padding:10px 0 8px;
            font-size: $font-size-16;
            color: $medium-gray;

            &:before {
                content:"";
                display:block;
                position:absolute;
                top:auto;
                left:0;
                bottom:0;
                width:100%;
                height:2px;
                background-color:transparent;
            }
        }
    }
}

.match-content{
    @include layout-align();
}

.match-poster-ul{
    margin-top:20px;
    li {
        margin-top:20px;
    }
    .match-inr{
        margin-top:20px;
        padding:15px 15px 25px 15px;
        border:1px solid $light-gray;
        border-radius:5px;
        &:first-child {
            margin-top:0;
        }

        .match-list-head {
            @include layout-align($justify-content:space-between);
            .match-hd-left{
                @include layout-align($justify-content:flex-start);
            }
            .match-hd-right{
                .btn {
                    width:70px;
                    height:20px;
                    padding:0;
                    font-size:$font-size-10;
                    border: 1px solid #FFFFFF;
                    border-radius: 3px;
                }
            }
            .match-type{
                padding:5px 8px;
                color:#fff;
                font-size: $font-size-10;

                border-radius:3px;
                &.home{
                    background-color:#000000;
                }
                &.away{
                    background-color:#F4F4F4;
                    color:#000;
                }
            }

            .match-stadium {
                display:block;
                padding-left:6px;
                font-size: $font-size-12;
            }

            .match-day {
                display:block;
                font-size: $font-size-12;
            }
        }

        .match-content{
            @include layout-align($align-items:flex-start);
            padding-top:15px;
            .match-team {
                width:100px;
                &:nth-child(2){
                    width:calc(100% - 200px);
                }
                .team-name {
                    font-size: $font-size-14;
                    text-align:center;
                }
            }
            .match-info{
                text-align:center;

                .match-info-date {
                    font-size: $font-size-12;
                    font-weight:500;
                    text-align: center;
                }

                .match-play-type {
                    width:80px;
                    margin:10px auto 0;
                    font-size: $font-size-12;
                    font-weight: 700;
                    color:#fff;
                    text-align:center;
                    border-radius: 30px;
                    background-color: #E10600;
                    padding: 8px 0;

                    &.end{
                        background-color: #DCDCDC;
                    }
                }
                .match-finsh-scoreCheck{
                    margin-top:13px;
                    font-size:$font-size-14;
                    color:$medium-gray;
                }
                .match-score{
                    @include layout-align();
                    margin-top:13px;
                    span {
                        display: block;
                    }

                    .score-box{
                        @include layout-align();
                        margin:0 5px;
                        .score{
                            font-weight: 700;
                            font-size: $font-size-14;
                        }
                    }

                    .match-game-type {
                        width:20px;
                        height: 20px;
                        border-radius:50%;
                        font-size: $font-size-12;
                        font-weight:700;
                        line-height: 19px;
                        color:#fff;
                        &.win{
                            background-color: #E10600;
                        }
                        &.lose{
                            background-color: #999999;
                        }
                    }
                }
            }
        }
    }
}

.match-sort-box{
    .select {
        @include select();
        font-size:1.6rem;
        width:calc(100% - 94px);
        &:nth-child(2){
            width : 84px;
            margin-left:10px;
        }

        &#legRoundSelect{
            background-position:80% center;
        }
    }
}

/* match- */
.match-btn-box{
    @include layout-align($justify-content:flex-start);
    margin-top:20px;
    .btn{
        flex: 1 1 0;
        margin-left:8px;
        height:48px;
        &:first-child{
            margin-left:0;
        }
    }
}

.macth-banner-wrap{
    .match-btn-box{
        margin-top:10px;
    }
}
.match-banner-box {
    position:relative;
    width: 100%;
    margin-top:20px;
    padding-bottom:49.563%;
    background-image: url("../images/match/match.bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    box-sizing:border-box;

    &.home {
        background-image: url("../images/match/match-bg-home.png");
    }
    &.away{
        background-image: url("../images/match/match-bg-away.png");
    }

    &.end-home {
        background-image: url("../images/match/match-end-home.png");
    }
    &.end-away{
        background-image: url("../images/match/match-end-away.png");
    }

    .match-bn-inr{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        padding:15px 15px 25px 15px;

        .match-bn-hd{
            @include layout-align($justify-content:space-between);
            .match-hd-left{
                @include layout-align($justify-content:flex-start);
            }
            .match-hd-right{
                .btn {
                    width:70px;
                    height:20px;
                    padding:0;
                    font-size:$font-size-10;
                    border: 1px solid #FFFFFF;
                    border-radius: 3px;
                }
            }
            .match-stadium {
                display:block;
                padding-left:6px;
                font-size: $font-size-12;
                color:#fff;
            }
            .match-type{
                padding:5px 8px;
                color:#fff;
                font-size: $font-size-10;

                border-radius:3px;
                &.home{
                    background-color:#000000;
                }
                &.away{
                    background-color:#F4F4F4;
                    color:#000;
                }
            }
        }

        .match-bn-container{
            display: flex;
            height: 100%;
            .match-team{
                display: flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                width:40%;
                &:nth-child(2){
                    width:20%;
                    min-width: 100px;
                }
                .team-name{
                    font-size:$font-size-14;
                    color:#fff;
                    text-align: center;
                }
            }

            .match-vs{
                position:relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 8%;
                font-size: $font-size-12;
                color:#fff;
                text-align: center;

                .match-total-score {
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    padding-bottom:20px;

                    .next-match-score{
                        margin:0 6px;
                        font-size:$font-size-14;
                        font-weight: bold;
                    }

                    .win-team-type{
                        display: flex;
                        align-items:center;
                        justify-content: center;
                        width:20px;
                        height:20px;
                        font-size:$font-size-12;
                        font-weight:bold;
                        text-align:center;
                        border-radius: 50px;
                        background-color:$medium-gray;
                        &.active {
                            background-color: $red;
                        }
                    }
                }
            }


        }
    }
}
.match-container { }


.match-test{
    height:500px;
    background-color: #000;
}
