@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('../font/noto-sans-kr-v27-latin_korean-100.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('../font/noto-sans-kr-v27-latin_korean-300.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('../font/noto-sans-kr-v27-latin_korean-regular.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('../font/noto-sans-kr-v27-latin_korean-500.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('../font/noto-sans-kr-v27-latin_korean-700.woff') format('woff');
}

@font-face {
    font-family: 'Black Han Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../font/black-han-sans-regular.woff') format('woff');
}

@font-face {
    font-family: 'SBAggro';
    font-style: normal;
    font-weight: 400;
    src: url('../font/SBAggroL.woff') format('woff');
}

@font-face {
    font-family: 'SBAggro';
    font-style: normal;
    font-weight: 500;
    src: url('../font/SBAggroB.woff') format('woff');
}

@font-face {
    font-family: 'InkLipquid';
    font-style: normal;
    font-weight: 400;
    src: url('../font/InkLipquid.woff') format('woff');
}

