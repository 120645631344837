.event-wrap {
    .event-info {
        display: block;
        margin-bottom: 20px;
        padding-top: 32%;
        border:1px solid $gray;
        border-radius:5px;
        background:#fff url(#{$event}/bg_intro.png) 0 0 no-repeat;
        background-size: 100%;

        p {
            padding: 12px 0;
            font-size: $font-size-14;
            text-align: center;
            line-height: 1.4;
            span {
                font-weight:700;
                color: $red;
            }
        }
    }

    .event-information{
        .title {
            margin-bottom: 10px;
            font-size: $font-size-12;
            font-weight: 500;
        }
    }

    .macth-banner-wrap{
        margin-bottom: 30px;
    }
}

.event-voting {
    .inner {
        padding-bottom: 78px;
        &.voting{
            padding-bottom: 10px;
        }
    }
    .player-list-wrap{
        .player-list {
            width: calc(33.3333% - 6.6666px);
            min-height: 170px;
            margin:0 0 10px 10px;

            &:nth-child(3n-2) {
                margin-left: 0;
            }
            &.vote {
                box-shadow:none;
            }
            &.active {
                border: 1px solid $red;
            }
        }

    }
    .radio-input{
        position: absolute;
        right: 10px;
        width: 20px;
        height: 20px;
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $gray url(#{$common}/icon_checkbox.svg) 50% 50% no-repeat;
            transition: 0.3s;
        }
        &:checked {
            &:before {
                background-color: $red;
            }
            + .radiobox-basic{
                box-shadow:none;
                border:1px solid $red;
            }
        }

    }
    .radiobox-basic{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
        border-radius:5px;
        box-shadow:0 0 3px $gray;

    }

    .floating{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        padding:15px 16px;
        background: $white;
    }
}

.deeplink-wrap {
    text-align: center;

    .deeplink-inner {
        padding: 50px 0;
    }

    .title {
        margin-bottom: 20px;
        padding-bottom: 34px;
        font-weight: 700;
        background: url(#{$common}/logo.svg) 50% 100% no-repeat;
        strong {
            color: $red;
        }
    }

    .text {
        margin-bottom: 40px;
        padding-top: 95%;
        background: url(#{$common}/deeplink_bg.png) 100% 100% no-repeat;
        background-size: auto 100%;
        font-size: $font-size-14;
        font-weight: 500;
        line-height: 1.6;
        span {
            color: $red;
        }

    }

    .button-wrap {
        @include layout-align;
        padding: 0 20px;
    }

}
.bnr_detail_wrap {
    position:relative;
    .btm_btn {
        background:$white;
        bottom:0;
        height:78px;
        left:0;
        padding:15px 16px;
        position:sticky;
        right:0;
        width:100%;
        z-index:5;
        button {
            background:rgb(150,14,19);
            background:linear-gradient(90deg, rgba(150,14,19,1) 0%, rgba(225,6,0,1) 100%);
            border-radius:5px;
            color:$white;
            font-size:16px;
            font-weight:700;
            height:48px;
            line-height:48px;
            text-align:center;
            transition:.3s;
            width:100%;
        }
    }
}

.membership-wrap {
    .membership-each {
        border-bottom:10px solid $white-gray;
        padding:30px 16px 0;
        & > h3 {
            color:$black;
            display:block;
            font-size:16px;
            font-weight:700;
        }
        & > .subttl {
            color:$black;
            display:block;
            font-size:14px;
            font-weight:700;
            margin-top:15px;
            padding-left:22px;
            position:relative;
            &:before {
                background:$black;
                border-radius:50%;
                content:"";
                height:4px;
                left:9px;
                margin-top:-2px;
                position:absolute;
                top:50%;
                width:4px;
            }
        }
        .grade-adv-list {
            li {
                border-bottom:1px solid $white-gray;
                display:table;
                min-height:100px;
                padding-left:75px;
                position:relative;
                width:100%;
                &:last-child {
                    border-bottom:0;
                }
                & > .cont {
                    display:table-cell;
                    vertical-align:middle;
                    & > strong {
                        color:$black;
                        font-size:16px;
                        font-weight:700;
                    }
                    dl {
                        font-size:0;
                        margin-top:6px;
                        text-align:left;
                        dt, dd {
                            color:$black;
                            display:inline-block;
                            font-size:12px;
                            line-height:1.2;
                            vertical-align:top;
                        }
                        dt {
                            font-weight:700;
                            width:28px;
                        }
                        dd {
                            font-weight:400;
                            width:calc(100% - 28px);
                            & > b {
                                font-size:12px;
                                font-weight:700;
                            }
                            &.red {
                                color:$red;
                            }
                        }
                    }
                }
                &.white {
                    background:url(#{$membership}/lv_w.svg) left center / 60px auto no-repeat;
                }
                &.green {
                    background:url(#{$membership}/lv_g.svg) left center / 60px auto no-repeat;
                }
                &.black {
                    background:url(#{$membership}/lv_b.svg) left center / 60px auto no-repeat;
                }
                &.red {
                    background:url(#{$membership}/lv_r.svg) left center / 60px auto no-repeat;
                }
            }
        }
        .point-adv-list {
            margin-top:25px;
            li {
                display:table;
                margin-bottom:18px;
                min-height:50px;
                padding-left:65px;
                position:relative;
                width:100%;
                &:last-child {
                    margin-bottom:30px;
                }
                & > .cont {
                    display:table-cell;
                    vertical-align:middle;
                    & > * {
                        color:$black;
                        display:block;
                        font-weight:400;
                        margin-top:8px;
                    }
                    & > strong {
                        font-size:14px;
                        font-weight:700;
                        margin-top:0;
                    }
                    & > p {
                        font-size:12px;
                    }
                    & > span {
                        color:$green;
                        font-size:12px;
                        line-height:1.2;
                    }
                }
                &.icon-attend {
                    background:url(#{$membership}/icon_attend.png) left center / 50px auto no-repeat;
                }
                &.icon-fan {
                    background:url(#{$membership}/icon_fan.png) left center / 50px auto no-repeat;
                }
                &.icon-showpepper {
                    background:url(#{$membership}/icon_showpepper.png) left center / 50px auto no-repeat;
                }
                &.icon-reply {
                    background:url(#{$membership}/icon_reply.png) left center / 50px auto no-repeat;
                }
                &.icon-fav {
                    background:url(#{$membership}/icon_fav.png) left center / 50px auto no-repeat;
                }
                &.icon-1st {
                    background:url(#{$membership}/icon_game_1st.png) left center / 50px auto no-repeat;
                }
                &.icon-2nd {
                    background:url(#{$membership}/icon_game_2nd.png) left center / 50px auto no-repeat;
                }
                &.icon-3rd {
                    background:url(#{$membership}/icon_game_3rd.png) left center / 50px auto no-repeat;
                }
                &.icon-gift {
                    background:url(#{$membership}/icon_gift.png) left center / 50px auto no-repeat;
                }
                &.icon-predict {
                    background:url(#{$membership}/icon_predict.png) left center / 50px auto no-repeat;
                }
                &.icon-join {
                    background:url(#{$membership}/icon_join.png) left center / 50px auto no-repeat;
                }
                &.icon-luckydraw {
                    background:url(#{$membership}/icon_luckydraw.png) left center / 50px auto no-repeat;
                }
                &.icon-ticket {
                    background:url(#{$membership}/icon_ticket.png) left center / 50px auto no-repeat;
                }
            }
        }
    }
    .membership-warn {
        padding:35px 16px 30px;
        strong {
            color:$black;
            display:block;
            font-size:12px;
            font-weight:500;
        }
        ul {
            margin-top:16px;
            li {
                color:$medium-gray;
                font-size:11px;
                font-weight:400;
                line-height:1.4;
                padding-left:17px;
                position:relative;
                &:before {
                    background:$medium-gray;
                    border-radius:50%;
                    content:"";
                    height:4px;
                    left:7px;
                    position:absolute;
                    top:5px;
                    width:4px;
                }
            }
        }
    }
}

.ediya-top {
    height:auto;
    position:relative;
    & > img {
        width:100%;
    }
    .navy {
        background:url(#{$event}/ediya_231011/top_navy.png) center top / auto 400px repeat-x;
        height:400px;
    }
    .yellow {
        background:url(#{$event}/ediya_231011/top_yellow.png) center top / auto 200px repeat-x;
        height:200px;
    }
    & > .inner {
        left:50%;
        padding:30px 16px 0;
        position:absolute;
        top:0;
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
        z-index:5;
        .remain-cpn-stat {
            background:$white;
            border-radius:20px;
            font-size:0;
            height:40px;
            text-align:center;
            width:100%;
            & > * {
                color:$black;
                display:inline-block;
                font-size:14px;
                line-height:40px;
            }
            & > dt {
                font-weight:400;
            }
            & > dd {
                font-weight:700;
                margin-left:3px;
                strong {
                    color:$red;
                    font-weight:700;
                }
            }
        }
    }
}
.ediya-mid {
    background:#ffd749;
    padding:10px 0 30px;
    & > .inner {
        padding:0 16px;
        .mid-each {
            margin-bottom:30px;
            h3 {
                display:block;
                height:22px;
                margin:0 auto 20px;
                overflow:hidden;
                text-indent:-20000px;
                &.guide {
                    background:url(#{$event}/ediya_231011/subttl01.png) center / 100% auto no-repeat;
                    width:122px;
                }
                &.usage {
                    background:url(#{$event}/ediya_231011/subttl02.png) center / 100% auto no-repeat;
                    width:150px;
                }
            }
            .cont-bg {
                background:$white;
                border-radius:5px;
                padding:25px 15px;
                dl {
                    font-size:0;
                    margin-bottom:10px;
                    text-align:left;
                    &:last-of-type {
                        margin-bottom:0;
                    }
                    * {
                        color:$black;
                        display:inline-block;
                        font-size:14px;
                    }
                    dt {
                        font-weight:700;
                    }
                    dd {
                        font-weight:400;
                        margin-left:3px;
                    }
                }
                ol {
                    li {
                        color:$black;
                        font-size:14px;
                        margin-bottom:10px;
                        b {
                            font-size:14px;
                            font-weight:700;
                        }
                    }
                }
                & > p {
                    color:$dark-gray;
                    font-size:12px;
                }
            }
        }
        & > button {
            background:$gray;
            border-radius:5px;
            color:$white;
            display:block;
            font-size:16px;
            font-weight:700;
            height:48px;
            line-height:48px;
            text-align:center;
            width:100%;
            &.active {
                background:#001e60;
            }
        }
    }
}
.ediya-cpn-regi {
    background:#001e60;
    padding:30px 0;
    .inner {
        padding:0 16px;
        & > h3 {
            background:url(#{$event}/ediya_231011/subttl03.png) center / 100% auto no-repeat;
            display:block;
            height:22px;
            margin:0 auto 20px;
            overflow:hidden;
            text-indent:-20000px;
            width:158px;
        }
        & > p {
            color:$white;
            font-size:14px;
            font-weight:400;
            text-align:center;
        }
        .sr-num-copy {
            background:$white;
            border-radius:5px;
            height:60px;
            margin-top:20px;
            position:relative;
            & > p {
                color:$black;
                font-size:16px;
                font-weight:400;
                left:15px;
                position:absolute;
                top:50%;
                -webkit-transform:translateY(-50%);
                transform:translateY(-50%);
            }
            & > button {
                border:1px solid $gray;
                border-radius:12px;
                color:$black;
                font-size:10px;
                font-weight:500;
                height:24px;
                line-height:22px;
                margin-top:-12px;
                padding:0 14px;
                position:absolute;
                right:15px;
                text-align:center;
                top:50%;
            }
        }
        & > button {
            background:$white;
            border-radius:5px;
            color:$black;
            font-size:16px;
            font-weight:700;
            height:48px;
            margin-top:20px;
            text-align:center;
            width:100%;
        }
    }
}
.cpn-warn {
    padding:33px 0 30px;
    .inner {
        padding:0 16px;
        strong {
            color:$black;
            display:block;
            font-size:12px;
            font-weight:500;
            margin-bottom:17px;
        }
        li {
            color:$dark-gray;
            font-size:11px;
            font-weight:400;
            line-height:1.4;
            padding-left:16px;
            position:relative;
            &:before {
                background:$dark-gray;
                border-radius:50%;
                content:"";
                height:3px;
                left:7px;
                position:absolute;
                top:7px;
                width:3px;
            }
        }
    }
}
.cpn-copy-comp {
    background:transparent;
    bottom:0;
    height:100px;
    left:0;
    position:fixed;
    width:100%;
    z-index:10;
    .inner {
        padding:22px 16px 0;
        max-width:360px;
        & > p,
        & > button {
            background:$black;
            border:0;
            border-radius:5px;
            color:$white;
            font-size:14px;
            font-weight:700;
            height:48px;
            line-height:48px;
            text-align:center;
        }
    }
}
