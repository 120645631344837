.pepper-guide {
    padding: 50px;
    .main-title {
        margin-bottom: 20px;
        font-size: 25px;
        font-weight: 700;
    }
    section {
        margin-bottom: 50px;
        padding: 25px;
        border: 1px solid $gray;
        .title {
            margin-bottom: 20px;
            font-size: $font-size-18;
            font-weight: 500;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .btn {
        &.block {
            margin-top: 14px;
        }
        &.large {
            display: inline-flex;
        }
    }
}
