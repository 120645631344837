@charset "utf-8";

// Media Query
@mixin mobile {
    @media screen and (min-width:0) and (max-width: #{$media-tablet}) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: #{$media-phone}) {
        @content;
    }
}

@mixin mini-phone {
    @media screen and (max-width: #{$media-mini-phone}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$media-phone + 1px}) and (max-width: #{$media-tablet}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$media-tablet + 1px}) {
        @content;
    }
}

// Layout
@mixin inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
}

// 말줄임
@mixin ellipsis {
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    vertical-align: middle;
}

// 말줄임 (여러줄)
@mixin ellipsis-long($line: 3) {
    overflow: hidden;
    display: -webkit-box;
    word-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

// 셀릭트
@mixin select($bdColor:$light-gray, $bdRadius:5px,){
    padding: 10px 28px 10px 10px;
    border-width: 1px;
    border-color: $bdColor;
    border-radius: $bdRadius;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 8px 14px;
    appearance: none;
    background-image:url("../images/common/select_arrow.svg");
    -webkit-appearance: none;
}

// 행간
@mixin line-height($font-size: $font-size-16) {
    @if $font-size == 1.1rem {
        line-height: 1.5;
    }

    @if $font-size == 1.2rem {
        line-height: 1.2;
    }

    @if $font-size >= 1.4rem {
        line-height: 1.4;
    }
}

// 텍스트 숨김 처리
@mixin text-hide {
    text-indent: -9999px;
}

// 폼 숨김 처리
@mixin form-hide {
    margin-left: -1px;
    clip-path: polygon(0 0, 0 0, 0 0);
    opacity: 0;
}

// 커스텀 스크롤바
@mixin scrollbar {
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 2px;
    }
}

// 버튼 초기화
@mixin button-reset($width: null, $height: null, $background-color: null, $border-color: null, $color: null) {
    width: $width;
    height: $height;
    background-color: $background-color;
    @if $border-color != null {
        border: 1px solid $border-color;
    }
    color: $color;
}

// 레이아웃 (디폴트 : 수평·수직 가운데 정렬)
@mixin layout-align($display: flex, $justify-content: center, $align-items: center) {
    display: $display;
    justify-content: $justify-content;
    align-items: $align-items;
}

// 백그라운드 초기화
@mixin background-reset($size: contain, $position: 50% 50%, $repeat: no-repeat) {
    background-position: $position;
    background-repeat: $repeat;
    background-size: $size;
}

// 백그라운드 배열 형태
@mixin background-list($items...) {
    @each $item in $items {
        &.btn-#{$item} {
            @include background-reset;
            @include text-hide;
            background-image: url(#{$common}/btn_#{$item}.png);
        }
    }
}

// 리스트 테마
@mixin list-theme($items...) {
    @each $item in $items {
        &-#{$item} {
            li {
                position: relative;
                margin-bottom: 5px;
                padding-left: 10px;
                &:before {
                    position: absolute;
                    left: 0;
                    @if $item == line {
                        content: '';
                        top: 10px;
                        width: 4px;
                        height: 1px;
                        background-color: $black;
                    } @else if $item == dot {
                        content: '·';
                    }
                }
            }
        }
    }
}

// Transition
@mixin transition($duration: 0.3s) {
    transition-duration: $duration;
}
