.pepper-about {
    .drag-down {
        position: fixed;
        top: auto;
        left: 50%;
        bottom:50px;
        z-index: 9999;
        transform: translate(-50%, 0);
        span{
            display: block;
            width: 12px;
            height: 12px;
            border-bottom: 2px solid white;
            border-right: 2px solid white;
            transform: rotate(45deg);
            margin: -10px;
            animation: dragDown 2s infinite;

            &:nth-child(2){
                animation-delay: -0.2s;
            }
            &:nth-child(3){
                animation-delay: -0.4s;
            }
        }
    }
}

@keyframes dragDown{
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
.pepper-about {
    overflow-y: auto;
    height: calc(100vh - #{$headerHeight});
    
    .inner {
        padding-top: 20px;

        section + section {
            padding-top: 20px;
        }

        section{
            text-align: center;
            img{
                border-radius: 10px;
            }

            .swiper {

                .swiper-pagination {
                    bottom: 0;
                    left: 50%;
                    margin-bottom: 12px;
                    position: absolute;
                    top: auto;
                    transform: translateX(-50%);
                    width: fit-content;
                    span.swiper-bullet {
                        background: #999999;
                        border-radius: 999px;
                        display: inline-block;
                        height: 5px;
                        opacity: 60%;
                        width: 5px;
                    }

                    span.swiper-bullet.active {
                        opacity: 1;
                    }

                    span.swiper-bullet + span.swiper-bullet {
                        margin-left: 8px;
                    }
                }
            }

            .content{
                margin-top: 20px;

                p.title {
                    border-radius: 5px;
                    background: #E10600;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $noto-sans;
                    margin-bottom: 15px;
                    padding: 5px 15px;
                    text-align: left;
                }

                div {
                    line-height: 1.4;
                    strong {
                        display: block;
                        font-family: $noto-sans;
                        font-size: 20px;
                        margin-bottom: 10px;
                        text-align: left;
                    }
                
                    p {
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .tab-swiper {
        background: #FFFFFF;
        left: 0;
        position: sticky;
        padding: 20px 11px;
        top: 0;
        z-index: 10;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $light-gray;
        }
    }
    .btn-tab {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 10px 5px;
        border-bottom: 1px solid transparent;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            z-index: 50;
            width: 100%;
            height: 2px;
            background-color: $red;
            transform: scaleX(0);
            transition: 0.3s;
        }
        &.active {
            color: $red;
            font-weight: 700;
            &:before {
                transform: scaleX(1);
            }
        }
    }
}

.about-swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .swiper {
        overflow: visible;
        width: 328px;
        height: 430px;
        .swiper-slide {
            &.swiper-slide:not(.swiper-slide-active) {
                padding-top: 30px;
                .theme-content {
                    transform: translateY(20px);
                    opacity: 0;
                }
                .map-wrap {
                    visibility: hidden;
                }
            }
        }
    }
    .card {
        overflow: hidden;
        height: 100%;
        border-radius: 15px;
        background-color: #f2f2f2;
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: 100%;
        &.greeting-card {
            &.main-card {
                background-image: url(#{$about}/bg_greeting_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_greeting_02.png);
            }
        }
        &.introduction-card {
            &.main-card {
                background-image: url(#{$about}/bg_introduction_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_introduction_02.png);
            }
        }
        &.emblem-card {
            &.main-card {
                background-image: url(#{$about}/bg_emblem_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_emblem_02.png);
            }
        }
        &.uniform-card {
            &.home {
                background-image: url(#{$about}/bg_uniform_01.png);
            }
            &.home-02 {
                background-image: url(#{$about}/bg_uniform_04.png);
            }
            &.away {
                background-image: url(#{$about}/bg_uniform_02.png);
            }
            &.libero {
                background-image: url(#{$about}/bg_uniform_03.png);
            }
        }
        &.character-card {
            &.main-card {
                background-image: url(#{$about}/bg_character_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_character_02.png);
            }
        }
        &.stadium-card {
            &.main-card {
                background-image: url(#{$about}/bg_stadium_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_stadium_02.png);
            }
        }
        &.map-card {
            &.main-card {
                background-image: url(#{$about}/bg_map_01.png);
            }
            &.sub-card {
                background-image: url(#{$about}/bg_map_02.png);
            }
        }
        .theme {
            @include layout-align($align-items: flex-end);
            height: 100%;
            padding: 30px 20px;
            color: $white;
            .slogan {
                @include line-height($font-size-20);
                font-size: $font-size-20;
                font-weight: 700;
                color: $white;
                word-break: keep-all;

            }
            .theme-content {
                width: 100%;
                transition:opacity 0.3s ease, transform 0.4s 0.1s ease-in-out;
            }
            &.main-theme {
                .title {
                    display: inline-block;
                    margin-bottom: 15px;
                    padding: 8px 15.5px;
                    background-color: $white;
                    border-radius: 15px;
                    color: $black;
                    font-size: $font-size-12;
                    font-weight: 500;

                }
                .image-wrap {
                    margin-bottom: 27px;
                    text-align: center;
                    img {
                        max-width: 280px;
                    }
                }
            }
            &.about-theme {
                .description {
                    margin-top: 15px;
                    p {
                        @include line-height($font-size-14);
                        margin-bottom: 15px;
                        font-size: $font-size-14;
                        word-break: keep-all;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &.map-theme {
                .map-wrap {
                    overflow: hidden;
                    position: relative;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    iframe {
                        max-width: 100%;
                        height: 200px;
                    }
                    .map-dim {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        transition: 0.3s;
                    }
                }
                .slogan {
                    margin-bottom: 10px;
                }
                .address {
                    @include line-height($font-size-14);
                    margin-bottom: 10px;
                    font-size: $font-size-14;
                    word-break: keep-all;

                }
                .transport-list {
                    @include layout-align($justify-content: flex-start);
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    dt {
                        flex-shrink: 0;
                        padding: 7px 16px;
                        border: 1px solid $white;
                        border-radius: 15px;
                        font-size: $font-size-12;
                    }
                    dd {
                        @include line-height($font-size-12);
                        padding-left: 10px;
                        font-size: $font-size-12;
                        word-break: keep-all;
                    }
                }
            }
        }
    }
}

#kakaoMap {
    .map-marker-info {
        @include layout-align;
        width: 154px;
        height: 21px;
        font-size: $font-size-11;
        &:hover {
            text-decoration: underline;
        }
    }
}
