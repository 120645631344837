// 선수 리스트
.player-list-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .player-list {
        position: relative;
        width: 49%;
        min-height: 220px;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0 0 3px #dcdcdc;
        box-sizing: border-box;
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: contain;
        &:nth-child(2n) {
            margin-left: 2%;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 9%;
            left: 7%;
            z-index: -1;
            width: 86%;
            height: 86%;
            border-radius: 5px;
            background: url(#{$player}/bg_player_logo.png) 50% 50% no-repeat;
            background-size: contain;
        }
        .player-link {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 10px;
            cursor:pointer;
            text-align: left;
            .label {
                @include layout-align($align-items: flex-start);
                position: absolute;
                top: 0;
                right: 10px;
                width: 34px;
                height: 42px;
                padding-top: 8px;
                background: url(#{$player}/bg_label.png) 50% 50% no-repeat;
                background-size: 100%;
                font-size: $font-size-14;
                font-weight: 700;
                color: $white;
            }
            .player-name {
                display: block;
                margin-bottom: 2px;
                font-size: $font-size-18;
                font-weight: 700;
                line-height: 1;
            }
            .position {
                display: block;
                font-size: $font-size-11;
                font-weight: 300;
            }
        }
    }
}

/* 선수 상세페이지 */
// 선수 리스트 슬라이드
.player-info-wrap {
    &.active{
        [class*="swiper-button-"]{
            display: none;
        }
        &:before{
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
        }
    }
    .swiper {
        .player-slide {
            position: relative;
            display: flex;
            justify-content:center;
            overflow:hidden;
            height: calc(100vh - 50px);
            background:#000 url(#{$player}/player_video_lodiong.gif) 50% 50% no-repeat;
            background-size: 50%;
            &.load-done {
                background:#000 50% 50% no-repeat;
            }
        }
        [class*="swiper-button-"] {
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.25) url(#{$player}/slide_arrow.svg) 13px 50% no-repeat;
            &.swiper-button-prev {
                left: 16px;
            }
            &.swiper-button-next {
                right: 16px;
            }
        }
    }
    video.video-img{
        opacity: 0;
        min-height: 100% !important;
        height: auto !important;
        width: auto !important;

        &.active {
            opacity: 1;
        }
    }
    img.video-img {
        position: absolute;
        bottom: 90px;
    }
}



//선수 정보
.player-bottom-sheet{
    div[data-swipeable="true"] + div[data-swipeable="true"]{
        height: 0;

    }
    div{
        box-shadow:none !important;
    }
    &.open{
        .player-information, .button-wrap{
            opacity: 1;
        }
        .floating-button{
            z-index: 0;
        }
        .channel{
            position: relative;
            z-index: 1;
            cursor:pointer;
        }
        .player-floating-box{
            &:before {
                transform: rotate(540deg);
            }
        }
    }

    &.close{
        .player-information,.button-wrap{
            opacity: 0;
        }
    }
}
.player-floating-box {
    position: relative;
    background: $deep-red;
    border-radius: 20px 20px 0 0;
    padding-top:30px;
    &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: calc(50% - 7px);
        width: 14px;
        height: 8px;
        background:url(#{$player}/player_open_arrow.png) center center no-repeat;
        background-size:14px 8px;
        transition:.5s;
    }
    .name-wrap {
        display: flex;
        align-items: center;
        .floating-button{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 90px;
            @include text-hide;
        }
        .name {
            padding-right: 10px;
            font-size: $font-size-24;
            font-weight: 700;
            color: $white;
        }
        .name-en {
            padding-right: 10px;
            color: $white;
            font-weight: 400;
        }
        .channel {
            z-index: 101;
            padding: 7px 19px 8px 8px;
            border: 1px solid $white;
            border-radius: 3px;
            background:url(#{$player}/icon_link_arrow.svg) 90% 50% no-repeat;
            font-size: $font-size-11;
            color: $white;
        }
        .label {
            @include layout-align($align-items: flex-start, );
            position: absolute;
            top: 0;
            right: 16px;
            width: 34px;
            height: 41px;
            padding-top: 8px;
            background: url(#{$player}/bg_label.png) 50% 50% no-repeat;
            background-size: 100%;
            font-size: $font-size-14;
            font-weight: 700;
            color: $white;
        }
    }
    .player-information {
        margin: 20px 0 30px;
        dl {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            dt {
                margin-right: 10px;
                padding: 6px 10px 7px;
                border-radius: 3px;
                background: $burgundy;
                font-size: $font-size-11;
                font-weight: 400;
                color: $white;
            }
            dd {
                font-size: $font-size-11;
                font-weight: 400;
                color: $white;
            }
        }
    }
}
