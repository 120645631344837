/* Header */
#header {
    @include layout-align;
    position: sticky;
    top: 0;
    z-index: 100;
    height: $headerHeight;
    padding: 0 50px;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    &.player-view {
        background: $black;
        color: $white;
        border-bottom: $black;
        .btn-page-back {
            background: url(#{$common}/btn_page_back_white.svg) 50% 50% no-repeat;
        }
    }
    .btn-page-back {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: url(#{$common}/btn_page_back.svg) 50% 50% no-repeat;
        background-size: 12px;
        font-size: 0;
    }
    .page-name {
        font-weight: 700;
    }
}
