.staff {
    overflow: hidden;
    .staff-section {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        .title {
            @include line-height($font-size-18);
            margin-bottom: 20px;
            padding-left: 28px;
            background: url(#{$team}/bul_title.svg) 0 2.5px no-repeat;
            font-size: $font-size-18;
            font-weight: 700;
        }
    }
}

// 구단조직
.none-swiper {
    .swiper-body {
        @include layout-align;
        overflow: hidden;
        margin:0  auto 20px;
        background-color: $white;
        border-radius: 20px;
        font-size: $font-size-24;
        font-weight: 700;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.staff-swiper {
    .swiper {
        width: 270px;
        padding-bottom: 20px;
        overflow: visible;
        &.swiper-cards {
            overflow: visible;
            padding-bottom: 0;
        }
    }
    .swiper-slide {
        @include layout-align;
        overflow: hidden;
        background-color: $white;
        border-radius: 20px;
        font-size: $font-size-24;
        font-weight: 700;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
    .swiper-body {
        @include layout-align($justify-content: flex-end);
        flex-direction: column;
        position: relative;
        width: 270px;
        height: 360px;
    }

    .image-wrap {
        @include layout-align($align-items: flex-end);
        height: 100%;
        background:$white url(#{$team}/bg_staff.png) 50% 50% no-repeat;
        background-size: 200px;
        img {
            width: 100%;
        }
    }

    .info-list {
        flex-shrink: 0;
        width: 100%;
        padding: 5px;
        background-color: #960e13;
        text-align: center;
        &.head.coach {
            background-color: #960e13;
        }
        &.coach {
            background-color: #d8905f;
        }
        &.analyst {
            background-color: #ff4c4c;
        }
        &.trainer {
            background-color: #7c9cd6;
        }
        &.manager {
            background-color: $black;
        }
        &.interpreter {
            background-color: #b2de84;
        }
        &.mark {
            &:before {
                content: '';
                position: absolute;
                right: 37px;
                top: -2px;
                width: 30px;
                height: 40px;
                background: url(#{$team}/icon_mark.png) 50% 50% no-repeat;
                background-size: 100%;
            }
        }
        li {
            @include line-height($font-size-16);
            font-size: $font-size-12;
            color: $white;
            letter-spacing: 0.05rem;
            &.position {
                font-weight: 700;
            }
            &.name {
                letter-spacing: 0.5rem;
            }
            &.eng-name {
                margin-bottom: 0;
                color: $light-gray;
            }
        }
    }

    .career-list-wrap {
        @include layout-align($align-items: flex-start);
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 13px;
        //background:$white url(#{$team}/bg_staff.png) 50% 50% no-repeat;
        background-size: 200px;
        section {
            width: 100%;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            .category {
                @include line-height($font-size-13);
                margin-bottom: 3px;
                font-size: $font-size-13;
                font-weight: 500;
                color: $dark-red;
            }
        }
        dl {
            //@include layout-align($align-items: flex-start);
            width: 100%;
            margin-bottom: 2px;
            &:last-child {
                margin-bottom: 0;
            }
            dt,
            dd {
                @include line-height($font-size-13);
                display: inline;
                font-size: $font-size-13;
                font-weight: 500;
            }
            dt {
                //flex-shrink: 0;
                margin-right: 3px;
                color: $medium-gray;
            }
            dd {
                //flex-grow: 1;
                letter-spacing: -0.2px;
                word-break: keep-all;
            }
        }
    }
}

.staff-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(220px, auto);
    gap: 20px 10px;
    margin-top: 20px;
}

.staff-card {
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    .top-info {
        font-family: $noto-sans;
        min-height: 70px;
        padding: 10px;
        strong {
            font-size: 18px;
            font-weight: 700;
        }
        p{
            font-size: 11px;
            font-weight: 400;
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
    .image-wrap{
        background: url("../images/team/bg_staff.png") center top 47% / 80% auto no-repeat;
        margin-top: -40px;
        padding-top: 106%;
        position: relative;

        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

.btn.btn-outline-02 { font-family: $noto-sans; font-size: 16px; font-weight: 500; }
// .btn.btn-outline-02.active { font-weight: 700; }

.canvas-backdrop {
    background-color: #000000;
    height: 100%;
    left: 0;
    opacity: .5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1040;
}

.canvas-container.show {
    transform: none;
}

.canvas-container {
    background-color: #72160D;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    bottom: 0;
    color: #FFFFFF;
    height: 97vh;
    left: 0;
    margin-top: -5px;
    overflow: hidden;
    position: fixed;
    padding: 0 10px 10px;
    top: auto;
    transform: translateY(100%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 1045;
    .staff-bottom{
        padding: 0 10px 10px;
    }

    header.canvas-top {
        position: relative;
        padding: 15px 10px;
        text-align: center;
        h3 {
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 700;
            padding: 1.5px 10px;
        }
        button.close-btn {
            background: url("../../libs/images/common/btn_modal_close_dark.svg") center / 100% auto;
            filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(196%) hue-rotate(37deg) brightness(113%) contrast(101%);
            height: 14px;
            margin-left: auto;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
        }
    }

    .canvas-cont {
        font-size: 13px;
        font-weight: 400;

        .top {
            background: #000000;
            border-radius: 5px;
            padding: 15px;
            position: relative;
            .navigator {
                display: flex;
                justify-content: space-between;
                left: 0;
                position: absolute;
                padding: 0 4px;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                button {
                    background-color: rgba(255, 255, 255, 0.20);
                    background-size: 30% auto;
                    background-repeat: no-repeat;
                    background-position: center left 40%;
                    border-radius: 999px;
                    display: block;
                    height: 24px;
                    position: static !important;
                    width: 24px;
                }

                button.prev { 
                    background-image: url("../images/common/btn_page_back_white.svg");
                }

                button.next {
                    background-image: url("../images/common/btn_page_back_white.svg");
                    transform: rotate(-180deg);
                }
            }

            .br-wrap {
                border: 1px solid transparent;
                border-radius: 5px;
                position: relative;


                &::before {
                    border-left: 1px solid #595454;
                    border-right: 1px solid #595454;
                    border-top: 1px solid #595454;
                    border-radius: 5px 5px 0 0;
                    content: "";
                    height: 40%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &::after {
                    border-left: 1px solid #595454;
                    border-right: 1px solid #595454;
                    border-bottom: 1px solid #595454;
                    border-radius: 0 0 5px 5px;
                    bottom: 0;
                    content: "";
                    height: 40%;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }

                .image-wrap {
                    background: url("../images/team/bg_staff.png") right 53% top 43% / 70% auto no-repeat;
                    margin-left: -8%;
                    margin-top: -10%;
                    position: relative;
                    width: 60%;
            
                    img {
                        height: 100%;
                        left: 0;
                        top: 0;
                        width: 100%;
                    }
                }
                .info-wrap {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-align: right;
                    height: 100%;
                    max-width: 60%;
                    width: fit-content;
                    position: absolute;
                    padding: 10px 20px;
                    right: 0;
                    top: 0;

                    div {
                        line-height: 1.4;
                    }
                    strong { 
                        font-size: 21px;
                        font-weight: 700;
                    }

                    p {
                        font-size: 15px;
                        margin-bottom: 0;
                    }

                    p.kr {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }
        .cont {
            font-size: 15px;
            margin-top: 20px;
            margin-bottom: 10px;

            .title {
                background: #7B2E2E;
                border-radius: 5px;
                margin-bottom: 20px;
                padding: 5px 15px;

                h3 {
                    color: #ffcccb;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            
            .desc {
                line-height: 1.6;
            }
        }

        .career {
            p { 
                margin: 0;
            }
        }
    }
    
}