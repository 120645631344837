/* 럭키 드로우 */
.lucky-draw {
    .tab-basic {
        margin-bottom: 20px;
    }
    .inner {
        padding-left: 0;
        padding-right: 0;
    }
}

/* 게시물 없을 경우 */
.none-result {
    @include line-height;
    padding: 30px 0;
    color: $medium-gray;
    font-weight: 700;
    text-align: center;
    .description {
        display: block;
        margin-top: 3px;
        font-weight: 400;
        font-size: $font-size-11;
    }
}

/* STEP 1 : 리스트 */
.lucky-draw {
    .step {
        .message {
            @include line-height($font-size-12);
            padding: 8px;
            background-color: $white-gray;
            font-size: $font-size-12;
            color: $dark-gray;
            text-align: center;
        }
    }
}

.lucky-draw-posts {
    background-color: $white-gray;
    li {
        margin-bottom: 8px;
        padding: 30px 16px;
        background-color: $white;
        text-align: center;
        &:last-child {
            margin-bottom: 0;
        }
        /*&.close-post {
            .post-image {
                opacity: 0.5;
            }
        }*/
        .title {
            @include line-height;
            margin-bottom: 20px;
        }
        .post-image-wrap {
            position: relative;
            margin-bottom: 20px;
            .post-image {
                position: relative;
                z-index: 10;
                width: 100%;
                aspect-ratio : 1;
                object-fit: contain;
            }
        }
        &:not(.close-post) {
            .post-image-wrap {
                //&:before {
                //    content: '';
                //    display: block;
                //    position: absolute;
                //    left: 14px;
                //    top: 14px;
                //    width: calc(100% - 28px);
                //    height: calc(100% - 28px);
                //    background-color: $green;
                //    border-radius: 50%;
                //}
            }
        }
        .btn.large {
            max-width: 100%;
        }
    }
}

/* STEP 2 : 상세 */
.lucky-draw-swiper {
    margin-bottom: 30px;
    .swiper {
        padding-bottom: 20px;
    }
    .image-wrap {
        @include layout-align($align-items: flex-start);
        img {
            display: block;
            width: calc( 100% - 80px );
            aspect-ratio : 1;
            object-fit: cover;
        }
    }
}

.lucky-draw-information {
    header {
        margin-bottom: 20px;
        .title {
            @include ellipsis-long(2);
            @include line-height;
            font-weight: 500;
        }
        .sub-title {
            display: inline-block;
            margin-bottom: 10px;
            color: $red;
            font-weight: 700;
            text-decoration: underline;
        }
    }
    .details-list {
        dt {
            margin-bottom: 20px;
            font-weight: 500;
        }
        dd {
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid $light-gray;
            font-size: $font-size-14;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .point-note {
        padding: 30px 0;
        border-bottom: 1px solid $light-gray;
    }
    .button-wrap-single {
        margin-top: 20px;
    }
}

// 카운트 다운
.flip-clock-wrap {
    margin-bottom: 60px;
    .flip-clock {
        @include layout-align;
        max-width: 368px;
        margin: 0 auto;
        div {
            font-size: 4rem;
            @include mini-phone {
                font-size: 3.3rem;
            }
        }
        // Label
        ._2aOn7 {
            bottom: -10px;
            font-size: $font-size-14;
            color: $black;
            letter-spacing: 0.05em;
            transform: translate(-50%, 100%);
            @include mini-phone {
                font-size: $font-size-12;
            }
        }
        // Separators
        ._2hRXr {
            display: none;
        }
        ._3cpN7 {
            margin: 0 2%;
            font-family: $black-han;
            ._2EBbg {
                position: relative;
                width: 36px;
                height: 56px;
                box-shadow: none;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 5px);
                    z-index: 50;
                    width: 3px;
                    height: 10px;
                    background-color: $white;
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
                ._SKh-V,
                ._1GZQ7 {
                    border-bottom-color: $white;
                }
                @include mini-phone {
                    width: 32px;
                    height: 48px;
                }
            }
            // Seconds
            //&:last-child {
            //    display: none;
            //}
        }
        &.flip-clock-complete {
            ._2aOn7 {
                color: $gray;
            }
            ._2v-d4, ._106d2, ._SKh-V {
                background-color: $gray;
            }
        }
    }
}



.lucky-draw-view {
    .flip-countdown {
        margin-bottom: 30px;
        span {
            font-family: $black-han;
            box-sizing: border-box;
        }
        .flip-countdown-piece {
            margin:0 8px !important;
            .flip-countdown-card {
                margin-bottom: 10px;
                span {
                    font-size: 4rem !important;
                    @include mini-phone {
                        font-size: 3.3rem !important;
                    }
                }
            }
            .flip-countdown-title {
                font-size: $font-size-14 !important;
            }
        }
    }
}


/* STEP 3 : 응모하기 */
.lucky-draw-apply {
    // 응모하기 폼
    .apply-list {
        margin-bottom: 40px;
        dt {
            margin-bottom: 15px;
            font-weight: 500;
            &.required {
                &:after {
                    content: "*";
                    margin-left: 1px;
                    color: $red;
                }
            }
        }
        dd {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .button-wrap-single {
        margin-top: 20px;
        .checkbox-basic {
            display: inline-block;
        }
        .btn-default {
            margin-top: 40px;
        }
    }
}

// 유의사항
.point-note {
    .title {
        margin-bottom: 10px;
        font-size: $font-size-12;
        font-weight: 500;
        &.required {
            &:after {
                content: "*";
                margin-left: 1px;
                color: $red;
            }
        }
    }
}

.validation-message {
    @include line-height($font-size-12);
    position: relative;
    margin-top: 10px;
    padding-left: 18px;
    font-size: $font-size-12;
    color: $red;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 12px;
        height: 8px;
        background: url(#{$common}/arr_validation.svg) 0 0 no-repeat;
    }
}
