.news-wrap {
    .tab-wrap {
        position:sticky;
        position:-webkit-sticky;
        top:50px;
        background: #fff;
        button {
            width: 50%;
            height: 50px;
            margin: 0;
            color: $medium-gray
        }
    }
    .inner.notice {
        padding-top: 10px;
    }

    .event-banner {
        .list {
            margin-top:32px;
            &:first-child{
                margin-top:0;
            }
        }
        a {
            display: block;
            margin-bottom: 15px;
            padding: 42.074% 16px 12px;
            border-radius: 5px;
            text-align: center;
            background:#fff url(#{$event}/bg_intro.png) 0 0 no-repeat;
            background-size: 100%;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

            strong {
                display: block;
                margin-bottom: 5px;
                padding-top: 12px;
                font-size: $font-size-14;
                text-align:left;
            }
            span {
                font-size: $font-size-12;
                color: $medium-gray;
            }
        }
        .match {
            display: block;
            margin-top:32px;
            margin-bottom: 15px;
            padding:42.074% 16px 12px;
            border-radius: 5px;
            text-align: center;
            background:#fff url(#{$event}/banner_match.svg) 0 0 no-repeat;
            background-size: 100%;
            cursor: pointer;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

            strong {
                display: block;
                margin-bottom: 5px;
                padding-top: 12px;
                font-size: $font-size-14;
            }

            span {
                font-size: $font-size-12;
                color: $medium-gray;
            }
        }
    }
    .notice-list {
        .list-none {
            padding-top: 20px;
        }
        .notice {
            display: block;
            padding: 20px 20px 20px 0;
            border-bottom: 1px solid $light-gray;
            background: url(#{$common}/basic_arrow.svg) 100% 50% no-repeat;

            .label {
                display: block;
                margin-bottom: 6px;
                font-size: $font-size-12;
                font-weight: 700;
                color: $green;
            }
            .title {
                overflow: hidden;
                display: block;
                margin-bottom: 6px;
                font-weight: 400;
                text-overflow:ellipsis;
                word-wrap:normal;
                white-space:nowrap;
            }
            .date {
                display: block;
                font-size: $font-size-12;
                color: $medium-gray;
            }
        }
    }
}

.notice-view {
    .notice-head {
        padding: 20px 16px;
        border-bottom:1px solid $light-gray;
        .title {
            @include line-height;
            margin-bottom: 6px;
            font-weight: 500;
            word-break:break-all;
        }
        .date {
            font-size: $font-size-12;
            color: $medium-gray;
        }
    }
    .notice-view {
        max-width:100%;
        padding: 20px 16px;
        .editor {
            word-break:break-all;
        }
    }
}

.policy-wrap{
    .editor {
        *{
            word-break:break-all;
            line-height: 1.5 !important;
        }
    }
}

.team_news_wrap {
    padding:20px 16px;
    .team_news_list {
        margin:-20px 0 0 -10px;
        ul {
            font-size:0;
            text-align:left;
            li {
                border-radius:5px;
                box-shadow:0 0 5px rgba(237, 237, 237, 1);
                display:inline-block;
                margin:20px 0 0 10px;
                min-height:170px;
                overflow:hidden;
                vertical-align:top;
                width:calc(50% - 10px);
                img {
                    height:110px;
                    width:100%;
                }
                div.txt {
                    padding:10px;
                    strong {
                        -webkit-box-orient:vertical;
                        color:$black;
                        display:-webkit-box;
                        font-size:14px;
                        -webkit-line-clamp:2;
                        line-height:1.4;
                        overflow:hidden;
                        text-overflow:ellipsis;
                    }
                    p {
                        color:$medium-gray;
                        font-size:12px;
                        margin-top:10px;
                    }
                }
            }
        }
    }
}

.news_cont_wrap {
    .news_ttl {
        border-bottom:1px solid $light-gray;
        padding:20px 16px;
        & > strong {
            color:$black;
            display:block;
            font-size:16px;
            line-height:1.4;
        }
        & > p {
            color:$medium-gray;
            font-size:12px;
            margin-top:8px;
        }
    }
    .news_cont {
        padding:20px 16px;
    }
}
