.match-p-wrap{
    .match-p-head{
        img {
            max-width: unset;
            width: 100%;
        }
    }
    .match-p-container{
        padding:30px 16px;
        background-color:#091730;

        .match-tt {
            text-align: center;
        }
        .match-stt{
            padding-top:10px;
            font-size: $font-size-14;
            color:#fff;
        }

        .match-game-info{
            background-color:#fff;
            border-radius: 5px;
        }


        .match-input-content {
            margin-top:24px;

            .match-input-box{
                @include layout-align();
                padding:15px;
                background-color: #fff;
                border-radius: 5px;
                margin-top:20px;

                &:first-child {
                    margin-top:0;
                }

                .hidden{
                    display:none;
                }

                .match-team-score {
                    @include layout-align();
                    width:138px;

                    .dot{
                        font-size:$font-size-20;
                        font-weight:700;
                        color:$gray;

                        &.active{
                            color:$black;
                        }
                    }
                    .total-score {
                        font-size:$font-size-24;
                        color:$gray;
                        font-weight:700;
                        &.home-score {
                            margin:0 15px 0 10px;
                            &.win{
                                color:$red;
                            }
                            &.lose{
                                color:$medium-gray;
                            }
                        }
                        &.away-score{
                            margin:0 10px 0 15px;
                            &.win{
                                color:$red;
                            }
                            &.lose{
                                color:$medium-gray;
                            }
                        }
                    }

                    .total-winner{
                        @include layout-align();
                        width:20px;
                        height:20px;
                        font-size:$font-size-12;
                        color:#fff;
                        border-radius:100%;

                        &.win {
                            background-color: $red;
                        }
                        &.lose {
                            background-color:$medium-gray;
                        }
                    }

                }

                .match-choose-team {
                    width: calc(50% - (138px/2));
                    .team {
                        height: 100%;
                        &.filter-img {
                            filter:grayscale(1);
                        }
                    }
                }
            }

            .match-set-input{
                ul {
                    margin-top:20px;
                    li{
                        @include layout-align();
                        position:relative;
                        &:first-child{
                            .match-set{
                                padding-top:20px;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                                &:before{
                                    content:"";
                                    position:absolute;
                                    top:-20px;
                                    left:50%;
                                    display: block;
                                    width:20px;
                                    height:20px;
                                    margin-left:-10px;
                                    background-color: #fff;
                                }
                            }
                        }

                        &:last-child{
                            .match-set{
                                padding-bottom:20px;
                                border-bottom-left-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }
                        }

                        .match-set{
                            position:relative;
                            width:calc(50% - 5px);
                            background-color: #fff;
                            padding:10px 0;
                            &.home-set{
                                margin-right:5px;
                                padding-left:20px;
                                padding-right:30px;
                                button{
                                    margin-right:10px;
                                }
                            }

                            &.away-set{
                                margin-left:5px;
                                padding-left:30px;
                                padding-right:20px;

                                button{
                                    margin-left:10px;
                                }
                            }

                            button {
                                width: 40px;
                                height: 40px;
                                font-size:$font-size-12;
                                border-radius: 50%;
                                border:1px dashed $gray;
                                &:disabled {
                                    color:$medium-gray;
                                    background-color:$gray;
                                }
                            }

                            input[type="number"] {
                                width:calc(100% - 50px);
                                height:40px;
                                font-size:$font-size-14;
                                color:#000;
                                border:1px solid $gray;
                                text-align: center;
                                &:disabled {
                                    color:$medium-gray;
                                    background-color:$gray;
                                }

                                &.winner {
                                    border-color:$red;
                                }
                            }
                        }

                        &.rightWin {
                            .home-set{
                                button{
                                    border:0;
                                    background-color:$medium-gray;
                                    color:#fff;
                                }
                            }

                            .away-set{
                                button{
                                    border:0;
                                    background-color:$red;
                                    color:#fff;
                                }
                            }
                            .set-info {
                                &.save{
                                    &:before{
                                        background-color:$red;
                                    }
                                    &:after{

                                        display: none;
                                    }
                                }

                                &:after {
                                    content: "";
                                    position: absolute;
                                    top:-1px;
                                    left:auto;
                                    right:-7px;
                                    display: block;
                                    width: 20px;
                                    height: 40px;
                                    background-image: url("../images/matchPrediction/set_bg_right.png");
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                }
                            }
                        }

                        &.leftWin {
                            .home-set{
                                button{
                                    border:0;
                                    background-color:$red;
                                    color:#fff;
                                }
                            }

                            .away-set{
                                button{
                                    border:0;
                                    background-color:$medium-gray;
                                    color:#fff;
                                }
                            }

                            .set-info {
                                &.save{
                                    &:before{
                                        background-color:$red;
                                    }
                                    &:after{

                                        display: none;
                                    }
                                }
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top:-1px;
                                    left:-7px;
                                    display: block;
                                    width: 20px;
                                    height: 40px;
                                    background-image: url("../images/matchPrediction/set_bg_left.png");
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                }
                            }
                        }

                        .set-info {
                            position:absolute;
                            top:50%;
                            left:50%;
                            display:flex;
                            align-items:center;
                            justify-content:center;
                            width:38px;
                            height:38px;
                            margin-left:-19px;
                            margin-top:-19px;
                            background-repeat:no-repeat;

                            p {
                                position: relative;
                                z-index: 1;
                                font-size:$font-size-11;
                                color:#fff;
                            }


                            &:before {
                                content:"";
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 0;
                                display:block;
                                width: 100%;
                                height: 100%;
                                transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                transform-origin:center;
                                -moz-transform-origin:center;
                                -webkit-transform-origin:center;
                                background-color:#000;
                            }
                        }
                    }
                }
            }

            .match-reset-box{
                padding-top:10px;
                text-align: right;

                .match-reset-btn{
                    padding-right:20px;
                    background-image: url(../images/matchPrediction/icon_reset.svg);
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 12px 12px;
                    font-size:$font-size-12;
                    color:#fff;
                }
            }

            .match-tip-box{
                padding-top:20px;
                .match-tip {
                    padding:4px 8px;
                    font-size:$font-size-10;
                    color:#fff;
                    border-radius:3px;
                    background-color:$red;
                    margin-right:10px;
                }
                .match-tip-txt{
                    font-size: $font-size-12;
                    color:#fff;
                }
            }

            .match-submit-btn {
                padding-top:30px;
                button{
                    width:100%;
                    height: auto;
                    padding:16.21px 0;
                    line-height: 1.3;
                    font-size:$font-size-16;
                    color:#fff;
                    text-align: center;
                    border-radius: 5px;

                    &.match-df-confirm{
                        background-color: $gray;
                    }

                    &.match-fail-btn{
                        font-weight:700;
                        line-height:1.5;
                        background-color:#000;
                    }

                    &.match-btn-tt{
                        font-weight:700;
                    }
                }

                a {
                    width:100%;
                    height: 48px;
                    font-size:$font-size-16;
                    color:#fff;
                    text-align: center;
                    border-radius: 5px;
                    &.btn-success{
                        height:74px;
                        font-weight:700;
                        line-height:1.5;
                        background-color:#000;
                    }
                }

            }
        }
    }
    .match-p-guide {
        padding:30px 16px 35px;
        .title {
            padding-bottom: 10px;
            font-size:$font-size-12;
            font-weight:500;
        }
        .chk-last-matches {
            margin-top:30px;
        }
    }
}

